import React, { useEffect } from 'react'
import { Card, Flex, Space, Typography } from 'antd'
import styles from './AnalysisSummary.module.css'
import { AnalysisDeliveryMessageBtn } from './AnalysisDeliveryMessageBtn'
import JobsTable from './JobsTable'
import AnalysisDetails from './AnalysisDetails'
import { Analysis, Family } from '../interfaces'

const { Text } = Typography

interface AnalysisProps {
  analysis: Analysis
  family: Family
}

export const AnalysisSummary: React.FC<AnalysisProps> = ({ analysis, family }) => {
  useEffect(() => {
    document.title = `Case: ${analysis?.case_id}`
  }, [analysis])

  return (
    <Card className={styles.analysisInfo}>
      <Text strong>
        <Text strong>
          <Space>
            Case: {analysis?.case_id}
            <AnalysisDeliveryMessageBtn analysis={analysis} />
          </Space>
        </Text>
      </Text>
      <Flex justify="space-between">
        <AnalysisDetails family={family} analysis={analysis} />
        <JobsTable analysis={analysis} />
      </Flex>
    </Card>
  )
}
