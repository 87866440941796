import React from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { StopOutlined, CheckCircleOutlined } from '@ant-design/icons'

interface StatusBulkActionsProps {
  handleBulkAction: any
  ids: string[]
  updateHandler: any
  hasSelected: boolean
  isSelectedCaseInView: boolean
}

export const StatusBulkActions: React.FC<StatusBulkActionsProps> = ({
  handleBulkAction,
  ids,
  updateHandler,
  hasSelected,
  isSelectedCaseInView,
}) => {
  return !isSelectedCaseInView ? (
    <>
      <Popconfirm
        title="Some cases are filtered out due to active filters or sorting. Are you sure you want to set status to Failed?"
        onConfirm={() => handleBulkAction('status', 'failed', ids, updateHandler)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Mark as Failed" placement="bottom">
          <Button disabled={!hasSelected} icon={<StopOutlined />}></Button>
        </Tooltip>
      </Popconfirm>
      <Popconfirm
        title="Some cases are filtered out due to active filters or sorting. Are you sure you want to set status to Complete?"
        onConfirm={() => handleBulkAction('status', 'completed', ids, updateHandler)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Mark as Complete" placement="bottom">
          <Button disabled={!hasSelected} icon={<CheckCircleOutlined />}></Button>
        </Tooltip>
      </Popconfirm>
    </>
  ) : (
    <>
      <Popconfirm
        title="Are you sure you want to set status to Failed?"
        onConfirm={() => handleBulkAction('status', 'failed', ids, updateHandler)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Mark as Failed" placement="bottom">
          <Button disabled={!hasSelected} icon={<StopOutlined />}></Button>
        </Tooltip>
      </Popconfirm>
      <Popconfirm
        title="Are you sure you want to set status to Complete?"
        onConfirm={() => handleBulkAction('status', 'completed', ids, updateHandler)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Mark as Complete" placement="bottom">
          <Button disabled={!hasSelected} icon={<CheckCircleOutlined />}></Button>
        </Tooltip>
      </Popconfirm>
    </>
  )
}
