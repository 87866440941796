import React, { useState } from 'react'
import { Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { Loading } from '../../Common/Loading'
import { pageLoadTimeout } from '../costants'
import { buildColumnData } from '../helpers'

export const Milestones = ({ issues, columnIssues, milestones }) => {
  const [isLoading, setIsLoading] = useState(true)
  const dataSource: any = []

  setTimeout(() => setIsLoading(false), pageLoadTimeout)

  milestones.map((milestone) => {
    const milestoneObject: any = { milestone: milestone, issues: [] }
    issues.map((issue) => {
      columnIssues.map((column) => {
        if (column.issues.includes(issue.url)) {
          const issueObject = {
            url: issue.html_url,
            prio: column.column,
            number: issue.number,
            title: issue.title,
          }
          if (milestone?.id == issue.milestone?.id) milestoneObject.issues.push(issueObject)
        }
      })
    })
    dataSource.push(milestoneObject)
  })

  const projectColumns = buildColumnData(columnIssues)

  const columns: ColumnType<any>[] = [
    {
      title: 'Milestone',
      dataIndex: 'milestone',
      key: 'milestone',
      render: (milestone) => <a href={milestone.html_url}>{milestone.title}</a>,
      sorter: (a, b) => a.milestone.title.localeCompare(b.milestone.title),
    },
    ...projectColumns,
  ]
  return isLoading ? (
    <Loading />
  ) : (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={(item) => item.milestone.id}
      pagination={false}
      bordered
    />
  )
}
