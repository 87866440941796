export const firebaseConfigStage = {
  apiKey: 'AIzaSyDLevjwCM0fvvoqrSyxBQi6-JHM0gn7CQ0',
  authDomain: 'cigrid-stage.firebaseapp.com',
  databaseURL: 'https://cigrid-stage-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'cigrid-stage',
  appId: '1:57324363154:web:0774c2598826ad0833754b',
}

export const firebaseConfigProd = {
  apiKey: 'AIzaSyAzOhFj8lzedOnU8He70f4FddxwzuboEQs',
  authDomain: 'cigrid-98a19.firebaseapp.com',
  databaseURL: 'https://cigrid-98a19-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'cigrid-98a19',
  appId: '1:724431638879:web:892c712ae642c63fdaa6ea',
}
