import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import { EditableAnalysesTable } from '../EditableTable/EditableAnalysesTable'
import { OrderSummary } from './OrderSummary/OrderSummary'
import { getOrder } from '../services'
import { Loading } from '../../Common/Loading'
import { performBulkAction } from '../EditableTable/BulkActions/utils'
import { createNotification } from '../../Common/services/helpers/helpers'
import { PageNotFound } from '../../Common/PageNotFound'

const Order = ({ orderId }) => {
  const [order, setOrder] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [notFound, setNotFound] = useState<boolean>(false)

  useEffect(() => {
    fetchData()
  }, [orderId])

  const fetchData = () => {
    setIsLoading(true)
    getOrder(orderId)
      .then((response) => {
        setOrder(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setNotFound(true)
      })
  }

  const handleDelivery = async (analysisIds) => {
    if (analysisIds.length) {
      await performBulkAction('delivered', true, analysisIds, null)
      createNotification('success', 'Analyses marked as delivered')
      fetchData()
    } else {
      createNotification('error', 'Failed to mark analyses as delivered')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (notFound) {
    return <PageNotFound />
  }

  return (
    <Card>
      <OrderSummary order={order} handleDelivery={handleDelivery} />
      <EditableAnalysesTable workflow={order.workflow} orderId={orderId} refresh={false} />
    </Card>
  )
}

export const OrderPage = React.memo(Order)
