import React, { useState } from 'react'
import Table, { ColumnType } from 'antd/lib/table'
import { Loading } from '../../Common/Loading'
import { areaLabel, pageLoadTimeout } from '../costants'
import { buildColumnData } from '../helpers'

export const Areas = ({ issues, columnIssues, labels }) => {
  const [isLoading, setIsLoading] = useState(true)
  const dataSource: any = []
  const area: any = []
  const gitHubAreaUrl = 'https://github.com/Clinical-Genomics/project-planning/labels/'

  setTimeout(() => setIsLoading(false), pageLoadTimeout)

  labels.filter((label) => {
    if (label?.name.includes(areaLabel)) {
      area.push(label?.name)
    }
  })

  if (area.length > 0) {
    area.map((area) => {
      const areaObject: any = { area: area, issues: [] }
      issues.map((issue) => {
        columnIssues.map((column) => {
          if (column.issues.includes(issue.url)) {
            const issueObject = {
              url: issue.html_url,
              prio: column.column,
              number: issue.number,
              title: issue.title,
              label: issue.labels,
            }
            issue.labels.map((label) => {
              if (area == label?.name) areaObject.issues.push(issueObject)
            })
          }
        })
      })
      dataSource.push(areaObject)
    })
  }

  const projectColumns = buildColumnData(columnIssues)

  const columns: ColumnType<any>[] = [
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      render: (area) => <a href={gitHubAreaUrl + area}>{area.replace(areaLabel, '')}</a>,
      sorter: (a, b) => a.area.localeCompare(b.area),
    },
    ...projectColumns,
  ]

  return isLoading ? (
    <Loading />
  ) : (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={(item) => item.area}
      pagination={false}
      bordered
    />
  )
}
