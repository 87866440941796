import React from 'react'
import { Card } from 'antd'
import { getReceivedSamples } from '../../services'
import { TrendingGraph } from '../TrendingGraph'

export const SampleTrendingPage = () => {
  return (
    <Card>
      <TrendingGraph populateGraphMethod={getReceivedSamples} additionalParameters={[]} />
    </Card>
  )
}
