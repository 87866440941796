import { Avatar, Dropdown, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import React from 'react'

import styles from '../App.module.css'

const { Text } = Typography

export const DropDownMenu = ({ logout, settings, darkMode, themeSwitch }) => {
  return (
    <div className={styles.userDropdown}>
      <Dropdown
        placement="bottomLeft"
        arrow
        menu={{
          items: [
            {
              key: '1',
              label: (
                <div onClick={() => themeSwitch(darkMode)} style={{ cursor: 'pointer' }}>
                  <Text>Switch to {darkMode ? 'Light' : 'Dark'} mode</Text>
                </div>
              ),
            },
            {
              key: '2',
              label: (
                <div onClick={logout} style={{ cursor: 'pointer' }}>
                  <Text>Logout</Text>
                </div>
              ),
            },
          ],
        }}
      >
        <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
          <Space>
            <Avatar src={settings?.user?.imageUrl as string}></Avatar>
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  )
}
