import React from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

export const IncludeHiddenAnalyses = ({ includeHidden, includeHiddenToggle }) => {
  return (
    <>
      <Tooltip
        title={includeHidden ? 'Exclude Hidden analyses' : 'Include Hidden analyses'}
        placement="bottom"
      >
        <Button
          style={{ width: '100px' }}
          onClick={includeHiddenToggle}
          type={includeHidden ? 'primary' : 'default'}
          icon={includeHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        />
      </Tooltip>
    </>
  )
}
