import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Spin, Table, TablePaginationConfig, Tag, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { formatDate } from '../../Common/services/helpers/helpers'
import styles from './PlatesTable.module.css'
import { getPlates } from '../services'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FilterValue } from 'antd/lib/table/interface'

export const DynamicPlatesTable = () => {
  const [plates, setPlates] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInfiniteLoading, setIsInfiniteLoading] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [sortKey, setSortKey] = useState<string>()
  const [sortDirection, setSortDirection] = useState<string>()
  const { Text } = Typography

  const skip = 0
  const limit = 60

  const statsStyle = {
    total: { name: 'Tot', color: 'geekblue' },
    failed: { name: 'Fail', color: 'volcano' },
    commented: { name: 'Comm', color: 'cyan' },
    cancelled: { name: 'Canc', color: 'orange' },
    unknown: { name: 'Unknown', color: 'gold' },
    passed: { name: 'Pass', color: 'green' },
  }

  useEffect(() => {
    loadMoreData()
  }, [])

  const loadMoreData = () => {
    if (!isInfiniteLoading) {
      setIsInfiniteLoading(true)
      getPlates(sortKey, sortDirection, plates?.length, limit)
        .then((response) => {
          setPlates([...plates, ...response])
          setIsInfiniteLoading(false)
          setHasMore(response.length === limit)
          setIsLoading(false)
        })
        .catch(() => {
          setIsInfiniteLoading(false)
        })
    }
  }

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: Record<any, any>
  ) => {
    setIsLoading(true)
    getPlates(sorter?.column?.key, sorter?.order, skip, limit)
      .then((response) => {
        setSortKey(sorter?.column?.key)
        setSortDirection(sorter?.order)
        setPlates(response)
        setIsLoading(false)
        setHasMore(response.length === limit)
      })
      .catch(() => setIsLoading(false))
  }

  const columns = [
    {
      title: 'Plate',
      dataIndex: 'plate_id',
      key: 'plate_id',
      render: (text, record) => <Link to={`/genotype/plates/${record.id}`}>{text}</Link>,
      sorter: true,
    },
    {
      title: 'Stats',
      dataIndex: 'plate_status_counts',
      key: 'plate_status_counts',
      width: '35%',
      render: (stats) => {
        if (!stats) {
          return null
        }
        return (
          <Row>
            {Object.entries(stats).map((stat: any) => {
              return (
                <Col key={stat[0]}>
                  {stat[1] > 0 ? (
                    <Tag color={statsStyle[stat[0]]?.color}>
                      <Row>{statsStyle[stat[0]]?.name}</Row>
                      <Row>{stat[1]}</Row>
                    </Tag>
                  ) : (
                    <Tag>
                      <Row>
                        <Text disabled>{statsStyle[stat[0]]?.name}</Text>
                      </Row>
                      <Row>
                        <Text disabled>{stat[1]}</Text>
                      </Row>
                    </Tag>
                  )}
                </Col>
              )
            })}
          </Row>
        )
      },
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => formatDate(date),
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'signed_by',
      key: 'signed_by',
      width: 0,
      render: (name) => {
        return name ? <Tag color="geekblue">SIGNED</Tag> : <Tag>UNSIGNED</Tag>
      },
    },
    {
      title: 'Signed By',
      dataIndex: 'user',
      key: 'signed_by',
      render: (user) => <Link to={`/genotype/users/${user?.id}`}>{user?.name}</Link>,
    },
    {
      title: 'Signed On',
      dataIndex: 'signed_at',
      key: 'signed_at',
      render: (date) => formatDate(date),
      sorter: true,
    },
  ]

  return (
    <>
      {plates && (
        <InfiniteScroll
          dataLength={plates?.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={
            <Spin
              style={{
                width: '100%',
                padding: 10,
              }}
            />
          }
          endMessage={<Divider plain>No more plates to load</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <Table
            loading={isLoading}
            dataSource={plates}
            columns={columns}
            pagination={false}
            rowKey={(plate) => plate.plate_id}
            tableLayout={'fixed'}
            rowClassName={(plate) =>
              plate.signed_by ? `${styles.customRow} ${styles.signed}` : styles.customRow
            }
            onChange={onTableChange}
          />
        </InfiniteScroll>
      )}
    </>
  )
}
