import { setupWorker, rest } from 'msw'
import { orderMock, ordersMock } from './modules/Trailblazer/mocks'

const { REACT_APP_BACKEND_TRAILBLAZER_URL } = process.env // Mock analyses api
const { REACT_APP_BACKEND_CLINICAL_URL } = process.env // Mock orders api

const getEndpoints = {
  analyses: 'analyses?workflow=&pageSize=50&page=1',
  orders: 'orders?workflow=',
  order: 'orders/7',
}

const handleCaseRequest = (req, res, ctx) => {
  const statusCode = 200 as number // Set the desired status code for the response
  let message

  switch (statusCode) {
    case 200:
      return res(ctx.status(statusCode), ctx.json(orderMock))
    //return res(ctx.status(statusCode), ctx.json(ordersMock))
    case 401:
      message = 'Unauthorized: User is not authenticated'
      break
    case 404:
      message = 'Not found: The requested resource does not exist'
      break
    case 409:
      message = "Conflict: Sample can't be found in the database"
      break
    default:
      message = 'Internal server error: Something went wrong on the server'
  }

  return res(ctx.status(statusCode), ctx.json({ message }))
}

const worker = setupWorker(
  rest.get(`${REACT_APP_BACKEND_CLINICAL_URL}/${getEndpoints.order}`, handleCaseRequest)
  //rest.get(`${REACT_APP_BACKEND_CLINICAL_URL}/${getEndpoints.orders}`, handleCaseRequest)
)

worker.start()
