import React, { useState } from 'react'
import { Button, Form, Input, Modal, Result } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { postUser } from '../services'

export const NewUserModal = ({ updateUsers }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<boolean>(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const registerUser = (user) => {
    postUser(user).then(() => {
      updateUsers()
      setIsRegistrationSuccessful(true)
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsRegistrationSuccessful(false)
  }
  return (
    <>
      <Button type={'primary'} onClick={showModal}>
        <UserAddOutlined style={{ fontSize: '20px' }} />
      </Button>
      <Modal
        title="Add new Genotype user"
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        {!isRegistrationSuccessful && (
          <Form name="basic" onFinish={registerUser} autoComplete="off">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input an email',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `Please input the user's full name`,
                },
              ]}
            >
              <Input placeholder="Full name" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type={'primary'}>
                Add user
              </Button>
            </Form.Item>
          </Form>
        )}
        {isRegistrationSuccessful && (
          <div>
            <Result status="success" title="New user added!" />
          </div>
        )}
      </Modal>
    </>
  )
}
