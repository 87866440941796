import React from 'react'
import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { appMenuItems } from './AppMenuItems'

export const AppMenu = ({ settings, darkMode }) => {
  const menuItems = appMenuItems(settings)
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={['home']}
      selectedKeys={[useLocation().pathname.split('/')[1]]}
      items={menuItems}
      style={{ backgroundColor: darkMode ? '#141414' : '', color: 'white' }}
    />
  )
}
