import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import styles from '../EditableTable.module.css'
import { CloseOutlined } from '@ant-design/icons'

export const CancelAction = ({ analysis, handleCancel }) => {
  return !analysis.is_cancellable ? null : (
    <Popconfirm
      title="Are you sure you want to cancel the analysis?"
      onConfirm={() => handleCancel(analysis.id)}
    >
      <div className={styles.actionIcons}>
        <Tooltip placement="bottom" title="Cancel analysis">
          <CloseOutlined disabled />
        </Tooltip>
      </div>
    </Popconfirm>
  )
}
