import { Tooltip } from 'antd'
import React from 'react'

export const buildColumnData = (columnIssues) => {
  return columnIssues
    .sort((a, b) => a.columnIndex - b.columnIndex)
    .map((column) => {
      return {
        title: column.column,
        dataIndex: column.column,
        key: column.column,
        defaultSortOrder: column.columnIndex === 0 ? 'descend' : false,
        sorter: (a, b) =>
          a.issues.filter((a) => a.prio === column?.column).length -
          b.issues.filter((a) => a.prio === column?.column).length,
        render: (item, area) => {
          return (
            <div style={{ display: 'flex', gap: 10 }}>
              {area.issues.map((task) => {
                if (task?.prio === column?.column)
                  return (
                    <Tooltip title={task.title} key={task.number}>
                      <a href={task.url}>{task.number}</a>
                    </Tooltip>
                  )
              })}
            </div>
          )
        },
      }
    })
}
