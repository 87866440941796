import React, { useState } from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { getOrderDeliveryMessage } from '../../services'
import { createNotification } from '../../../Common/services/helpers/helpers'

export const OrderDeliveryMessageBtn = ({ order, handleDelivery }) => {
  const summary = order?.summary
  const [analysisIds, setAnalysisIds] = useState([])
  const handleOnClick = async () => {
    const deliveryMessage = await getOrderDeliveryMessage(order?.id)
    if (deliveryMessage.analysis_ids) {
      setAnalysisIds(deliveryMessage.analysis_ids)
      await navigator.clipboard.writeText(deliveryMessage.message)
      createNotification(
        'success',
        'Message copied',
        'The delivery message has been copied to the clipboard successfully.'
      )
    }
  }

  return (
    <>
      <Tooltip title="Copy delivery message" placement="bottom">
        <Popconfirm
          title={'Do you want to mark the analyses as delivered?'}
          placement="topRight"
          onConfirm={() => handleDelivery(analysisIds)}
          disabled={summary.completed === 0}
        >
          <Button
            onClick={handleOnClick}
            icon={<CopyOutlined style={{ fontSize: '18px' }} />}
            type="text"
          />
        </Popconfirm>
      </Tooltip>
    </>
  )
}
