import React, { useEffect, useState } from 'react'
import mainStyles from '../../../App.module.css'
import { deleteUser, getUsers, putUserEmail } from '../services'
import { notification, Popconfirm, Table, Tag, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { NewUserModal } from '../NewUserModal/NewUserModal'
import { Loading } from '../../Common/Loading'
import { DeleteOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons'

export const UserListPage = () => {
  const [users, setUsers] = useState<any[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    updateUsersList()
  }, [])

  const toggleUserStatus = (id, email) => {
    if (email[0] === '_') putUserEmail(id, email.substring(1)).then(() => updateUsersList())
    else putUserEmail(id, `_${email}`).then(() => updateUsersList())
  }

  const updateUsersList = () => {
    getUsers()
      .then((response) => {
        setUsers(response)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const confirmDeleteUser = (username: string) => {
    deleteUser(username).then(() => {
      notification['success']({
        message: `User deleted`,
      })
      getUsers().then((users) => {
        setUsers(users)
      })
    })
  }

  const columns = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (name, user) => <Link to={`/genotype/users/${user.id}`}>{name}</Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'status',
      key: 'status',
      render: ({ email }) => {
        return email[0] === '_' ? <Tag>INACTIVE</Tag> : <Tag color={'cyan'}>ACTIVE</Tag>
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: ({ id, email }) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Delete user">
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => confirmDeleteUser(id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ fontSize: '20px' }} />
            </Popconfirm>
          </Tooltip>
          <div
            onClick={() => toggleUserStatus(id, email)}
            style={{ paddingLeft: 10, cursor: 'pointer' }}
          >
            {email[0] === '_' ? (
              <Tooltip title="Activate user">
                <SmileOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Tooltip>
            ) : (
              <Tooltip title="Inactivate user">
                <MehOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className={mainStyles.content}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {users && (
            <Table
              dataSource={users}
              columns={columns}
              pagination={false}
              rowKey={(user) => user.id}
              tableLayout={'fixed'}
              title={() => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <NewUserModal updateUsers={updateUsersList} />
                </div>
              )}
            />
          )}
        </>
      )}
    </div>
  )
}
