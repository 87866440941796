import React from 'react'
import { Card, Col, Progress, Tag, Typography } from 'antd'
import { formatDate } from '../../Common/services/helpers/helpers'
import { getPriorityTagColor, getStatusTagColor, getTypeTagColor } from '../../Common/styleHelpers'
import { supportSystemTicketLink } from '../constants'
import { Link } from 'react-router-dom'
const { Paragraph } = Typography

const resultsURL = (isStage, family) => {
  return {
    'MIP-DNA': {
      url: `https://scout${isStage ? '-stage' : ''}.scilifelab.se/${family.customer?.internal_id}/${
        family.name
      }`,
      displayName: 'Scout case',
    },
    BALSAMIC: {
      url: `https://scout${isStage ? '-stage' : ''}.scilifelab.se/${family.customer?.internal_id}/${
        family.name
      }`,
      displayName: 'Scout case',
    },
    'BALSAMIC-UMI': {
      url: `https://scout${isStage ? '-stage' : ''}.scilifelab.se/${family.customer?.internal_id}/${
        family.name
      }`,
      displayName: 'Scout case',
    },
    FLUFFY: {
      url: `https://statina${isStage ? '-stage.web.app' : '.clinicalgenomics.se'}/batches/${
        family?.links[0]?.sample?.order
      }`,
      displayName: 'Statina case',
    },
    RNAFUSION: {
      url: `https://scout${isStage ? '-stage' : ''}.scilifelab.se/${family.customer?.internal_id}/${
        family.name
      }`,
      displayName: 'Scout case',
    },
  }
}

const dataAnalysisToString = (dataAnalysis: string | string[]): string => {
  const tempDataAnalysis: any[] = []
  tempDataAnalysis?.push(dataAnalysis)
  return tempDataAnalysis?.flat()?.join(' ')
}
const AnalysisDetails = ({ family, analysis }) => {
  const isStage: boolean = window?.location.href?.toString()?.includes('stage')
  return (
    <Col span={10}>
      <Card bordered={false}>
        {analysis?.ticket_id && (
          <Paragraph>
            Ticket:{' '}
            <a href={supportSystemTicketLink(analysis?.ticket_id)}>
              {analysis?.ticket_id?.toString()}
            </a>
          </Paragraph>
        )}
        {analysis?.order_id && (
          <Paragraph>
            Order: <Link to={`/orders/order/${analysis?.order_id}`}>{analysis?.order_id}</Link>
          </Paragraph>
        )}
        {family && resultsURL(isStage, family)[analysis?.workflow]?.displayName && (
          <Paragraph>
            <a href={resultsURL(isStage, family)[analysis?.workflow]?.url}>
              {resultsURL(isStage, family)[analysis?.workflow]?.displayName}
            </a>
          </Paragraph>
        )}
        {family?.data_delivery && (
          <Paragraph>
            Data delivery <Tag>{dataAnalysisToString(family?.data_delivery)}</Tag>
          </Paragraph>
        )}
        {analysis?.id && <Paragraph>ID: {analysis?.id}</Paragraph>}
        {analysis?.version && <Paragraph>Version: {analysis?.version}</Paragraph>}
        {analysis?.started_at && (
          <Paragraph>Started At: {formatDate(analysis?.started_at)}</Paragraph>
        )}
        {analysis?.user?.name && <Paragraph>User: {analysis?.user?.name}</Paragraph>}
        {analysis?.type && (
          <Tag color={getTypeTagColor(analysis?.type)} key={analysis?.type}>
            {analysis?.type?.toUpperCase()}
          </Tag>
        )}
        {analysis?.priority && (
          <Tag color={getPriorityTagColor(analysis?.priority)} key={analysis?.priority}>
            {analysis?.priority?.toUpperCase()}
          </Tag>
        )}
        {analysis?.status && (
          <Tag color={getStatusTagColor(analysis?.status)} key={analysis?.status}>
            {analysis?.status?.toUpperCase()}
          </Tag>
        )}
        {analysis?.status === 'running' && (
          <Progress
            percent={Math.floor(analysis?.progress * 100)}
            style={{ width: '200px', margin: '16px 0' }}
          />
        )}
        {analysis?.comment && <Paragraph>{analysis?.comment}</Paragraph>}
      </Card>
    </Col>
  )
}

export default AnalysisDetails
