import React, { useState, useRef, useContext, useEffect } from 'react'
import { Form, Input } from 'antd'
import { editAnalysis } from '../services'
import { EditableContext } from './EditableContext'

const { TextArea } = Input

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: any) => {
  const [editing, setEditing] = useState<boolean>(false)
  const inputRef: any = useRef()
  const form: any = useContext(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      editAnalysis(undefined, record.id, 'comment', undefined, values.comment).then(() => {
        handleSave({ ...record, ...values })
        toggleEdit()
      })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 10,
        }}
        name={dataIndex}
      >
        <TextArea ref={inputRef} onPressEnter={save} onBlur={save} placeholder="Type comment" />
      </Form.Item>
    ) : (
      <div
        style={{
          paddingRight: 24,
          minHeight: 40,
          minWidth: 20,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}
