export const analysisMatch = { match: { params: { familyId: '34566' } } }

export const orderMock = {
  customer_id: 'cust004',
  order_date: '2024-01-00',
  order_id: 7,
  ticket_id: 353062,
  workflow: 'mip-dna',
  status_summary: {
    total: 11,
    delivered: 6,
    running: 1,
    failed: 1,
    cancelled: 1,
    sequencing: 1,
    lab_prep: 1,
  },
}

export const ordersMock = {
  orders: [
    {
      customer_id: 'cust139',
      order_date: '2024-01-30',
      order_id: 1,
      ticket_id: 124515,
      workflow: 'mip-dna',
      status_summary: {
        total: 7,
        delivered: 1,
        running: 0,
        failed: 0,
        cancelled: 2,
        sequencing: 1,
        lab_prep: 3,
      },
    },
    {
      customer_id: 'cust002',
      order_date: '2024-01-30',
      order_id: 2,
      ticket_id: 587841,
      workflow: 'balsamic',
      status_summary: {
        total: 9,
        delivered: 1,
        running: 1,
        failed: 0,
        cancelled: 0,
        sequencing: 3,
        lab_prep: 2,
      },
    },
    {
      customer_id: 'cust087',
      order_date: '2024-01-30',
      order_id: 3,
      ticket_id: 772082,
      workflow: 'balsamic',
      status_summary: {
        total: 8,
        delivered: 2,
        running: 1,
        failed: 0,
        cancelled: 1,
        sequencing: 0,
        lab_prep: 1,
      },
    },
    {
      customer_id: 'cust003',
      order_date: '2024-01-30',
      order_id: 4,
      ticket_id: 512736,
      workflow: 'mip-dna',
      status_summary: {
        total: 10,
        delivered: 3,
        running: 1,
        failed: 0,
        cancelled: 2,
        sequencing: 1,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust002',
      order_date: '2024-01-31',
      order_id: 5,
      ticket_id: 755205,
      workflow: 'mip-dna',
      status_summary: {
        total: 7,
        delivered: 4,
        running: 1,
        failed: 2,
        cancelled: 0,
        sequencing: 1,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust162',
      order_date: '2024-01-31',
      order_id: 6,
      ticket_id: 428846,
      workflow: 'balsamic',
      status_summary: {
        total: 6,
        delivered: 5,
        running: 1,
        failed: 0,
        cancelled: 0,
        sequencing: 0,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust004',
      order_date: '2024-01-31',
      order_id: 7,
      ticket_id: 353062,
      workflow: 'mip-dna',
      status_summary: {
        total: 8,
        delivered: 6,
        running: 1,
        failed: 1,
        cancelled: 0,
        sequencing: 0,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust032',
      order_date: '2024-01-31',
      order_id: 8,
      ticket_id: 692603,
      workflow: 'fluffy',
      status_summary: {
        total: 8,
        delivered: 7,
        running: 1,
        failed: 0,
        cancelled: 0,
        sequencing: 0,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust002',
      order_date: '2024-01-31',
      order_id: 9,
      ticket_id: 650388,
      workflow: 'mip-dna',
      status_summary: {
        total: 9,
        delivered: 8,
        running: 1,
        failed: 0,
        cancelled: 0,
        sequencing: 0,
        lab_prep: 0,
      },
    },
    {
      customer_id: 'cust002',
      order_date: '2024-01-31',
      order_id: 10,
      ticket_id: 663940,
      workflow: 'mip-dna',
      status_summary: {
        total: 14,
        delivered: 9,
        running: 1,
        failed: 4,
        cancelled: 0,
        sequencing: 0,
        lab_prep: 0,
      },
    },
  ],
}
