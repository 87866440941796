import React, { useState } from 'react'
import { Empty, Table, Tabs, Tag, Tooltip } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { Loading } from '../../Common/Loading'
import { UserProfile } from '../UserProfile'
import { Bar } from '@ant-design/charts'
import { useHistory, useLocation } from 'react-router-dom'
import { projectPlanningRootURL } from '../../Common/constants'

export const IssueDrivers = ({ issues, columnIssues, users }) => {
  const [isLoading, setIsLoading] = useState(true)
  const tableData: any = []
  const graphData: any = []
  const history = useHistory()
  const location = useLocation()

  setTimeout(() => setIsLoading(false), 5000)

  users.map((user) => {
    const userObject: any = { user: user, driver: [], assignees: [] }
    columnIssues.map((column) => {
      const isPrioColumn = column.column.includes('Prio')
      let issueCount = 0
      column.issues.map((columnIssue) => {
        const issue = issues.find((issue) => issue.url === columnIssue)
        if (issue) {
          const issueObject = {
            url: issue?.html_url,
            prio: column.column,
            number: issue?.number,
            title: issue?.title,
          }
          issue.assignees.map((assignee) => {
            if (assignee.login === user.login) {
              userObject.assignees.push(issueObject)
            }
          })
          if (issue.body?.match(/Driver\(s\):(.*)/)?.[1].includes(user.login)) {
            userObject.driver.push(issueObject)
            issueCount++
          }
        }
      })
      if (isPrioColumn)
        graphData.push({
          label: user.login,
          type: column.column,
          value: issueCount,
          prioCount: columnIssues.map((localColumn) => {
            return {
              index: localColumn.columnIndex,
              value: localColumn.column === column.column ? issueCount : 0,
            }
          }),
        })
    })
    tableData.push(userObject)
  })

  const projectColumns = columnIssues
    .sort((a, b) => a.columnIndex - b.columnIndex)
    .map((column) => {
      return {
        title: column.column,
        dataIndex: column.column,
        key: column.column,
        defaultSortOrder: column.columnIndex === 0 ? 'descend' : false,
        sorter: (a, b) =>
          a.driver.filter((a) => a.prio === column?.column).length +
          a.assignees.filter((a) => a.prio === column?.column).length -
          b.driver.filter((a) => a.prio === column?.column).length -
          b.assignees.filter((a) => a.prio === column?.column).length,
        render: (item, user) => {
          return (
            <div style={{ display: 'flex', gap: 10 }}>
              {user.driver.map((task) => {
                if (task?.prio === column?.column)
                  return (
                    <Tooltip title={`${task.title}`} key={`${user.login}${task.url}`}>
                      <a href={task.url}>
                        <Tag color={'blue'}>{task.number}</Tag>
                      </a>
                    </Tooltip>
                  )
              })}
              {user.assignees.map((task) => {
                if (task?.prio === column?.column)
                  return (
                    <Tooltip title={`${task.title}`} key={`${user.login}${task.url}${task.number}`}>
                      <a href={task.url}>{task.number} </a>
                    </Tooltip>
                  )
              })}
            </div>
          )
        },
      }
    })
  const columns: ColumnType<any>[] = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user) => <UserProfile user={user} />,
      sorter: (a, b) => a.user.login.localeCompare(b.user.login),
    },
    ...projectColumns,
  ]

  const recursiveSorter = (a, b) => {
    let resultA = 0
    let resultB = 0
    a.prioCount.map((localA, i) => {
      resultA = resultA + localA.value / ((i + 1) * (i + 1) * (i + 1))
      resultB = resultB + b.prioCount[i].value / ((i + 1) * (i + 1) * (i + 1))
    })
    return resultB - resultA
  }

  const tabs = [
    {
      label: 'Table',
      key: 'table',
      children: (
        <Table
          dataSource={tableData}
          columns={columns}
          rowKey={(item) => item.user.login}
          pagination={false}
          bordered
          scroll={{ x: 1000 }}
        />
      ),
    },
    {
      label: 'Graph',
      key: 'graph',
      children: (
        <div style={{ height: '70vh' }}>
          {graphData.length > 0 ? (
            <Bar
              {...{
                data: graphData.sort((a, b) => recursiveSorter(a, b)),
                xAxis: { position: 'left' },
                isStack: true,
                xField: 'value',
                yField: 'label',
                seriesField: 'type',
              }}
            />
          ) : (
            <Empty />
          )}
        </div>
      ),
    },
  ]

  return isLoading ? (
    <Loading />
  ) : (
    <Tabs
      items={tabs}
      activeKey={location.pathname.split('/')[3] || 'table'}
      onChange={(path) => {
        history.push(`${projectPlanningRootURL}/users/${path}`)
      }}
    />
  )
}
