import React, { useState } from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { FolderOpenOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const OrderOpeningAction = ({ order, handleOpening }) => {
  const [orderStatus, setOrderStatus] = useState(order?.is_open)

  const handleOpeningConfirmation = (status) => {
    handleOpening(order.id, status).then((response) => {
      setOrderStatus(response.is_open)
    })
  }

  return (
    <>
      {orderStatus ? (
        <Popconfirm
          title="Are you sure you want to mark the order as closed?"
          placement="topRight"
          onConfirm={() => handleOpeningConfirmation(false)}
        >
          <Tooltip placement="bottom" title="Mark order as Closed?">
            <Button icon={<CloseCircleOutlined style={{ fontSize: '18px' }} />} type="text" />
          </Tooltip>
        </Popconfirm>
      ) : (
        <Popconfirm
          title="Are you sure you want to mark the order as open?"
          placement="topRight"
          onConfirm={() => handleOpeningConfirmation(true)}
        >
          <Tooltip placement="bottom" title="Mark order as open?">
            <Button icon={<FolderOpenOutlined style={{ fontSize: '18px' }} />} type="text" />
          </Tooltip>
        </Popconfirm>
      )}
    </>
  )
}
