import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  genotypeSampleStats,
  genotypeSampleStatus,
  genotypeSampleStatusDetail,
  notAvailableTag,
  sexTags,
} from '../../Common/styleHelpers'
import styles from './SamplePage.module.css'
import { SampleMatchTable } from '../SampleMatchTable/SampleMatchTable'
import { Analysis, Sample, SampleDetail } from '../interfaces'
import { Card, Descriptions, Divider, Empty, Select, Tag, Typography } from 'antd'
import { getSample, putSample } from '../services'
import { formatDate } from '../../Common/services/helpers/helpers'
import { Loading } from '../../Common/Loading'
import { RootState } from '../../../domain/rootReducer'
import { connect } from 'react-redux'
import { AppState } from '../../../domain/settings/types'

const mapState = ({ settings }: RootState) => ({ settings } as const)

interface SamplePageProps extends RouteComponentProps {
  settings: AppState
}

export const SamplePageComponent = ({ settings, match }: SamplePageProps) => {
  const [sample, setSample] = useState<Sample>()
  const [analyses, setAnalyses] = useState<Analysis[]>()
  const [sampleDetail, setSampleDetail] = useState<SampleDetail>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { Paragraph } = Typography
  const { Option } = Select

  const {
    params: { id },
  }: any = match

  useEffect(() => {
    if (id) {
      getSample(id)
        .then((response: Sample) => {
          setSample(response)
          setAnalyses(response.analyses)
          setSampleDetail(response.detail)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }, [id])

  const handleChange = (value, parameter) => {
    putSample(id, parameter, value).then((response) => setSample(response))
  }

  return isLoading ? (
    <Loading />
  ) : sample ? (
    <Card
      className={
        settings?.themeMode ? styles[`dark${sample?.status}`] : styles[`light${sample?.status}`]
      }
    >
      <Descriptions title={sample.id} bordered className={styles.description}>
        <Descriptions.Item label="Created on">{formatDate(sample.created_at)}</Descriptions.Item>

        <Descriptions.Item label="Status" span={2}>
          <Select
            bordered={false}
            defaultValue={sample.status}
            onChange={(value) => handleChange(value, 'status')}
            style={{ width: 120 }}
          >
            {Object.keys(genotypeSampleStatus).map((status) => {
              return (
                <Option value={status} key={status}>
                  <Tag color={genotypeSampleStatus[status]}>{status?.toUpperCase()}</Tag>
                </Option>
              )
            })}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Status details">
          {sampleDetail &&
            Object.entries(sampleDetail.status).map((entry) => (
              <div key={entry[0]} className={styles.statusDetail}>
                {entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}
                {entry[1] !== null ? (
                  genotypeSampleStatusDetail[entry[1]]
                ) : (
                  <Tag className={styles.detailTag} color={notAvailableTag[entry[1]]}>
                    N/A
                  </Tag>
                )}
              </div>
            ))}
          <Divider />
          {sampleDetail &&
            Object.entries(sampleDetail.stats).map((entry) => (
              <div key={entry[0]} className={styles.statusDetail}>
                {entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}
                {entry[1] !== null ? (
                  <Tag className={styles.detailTag} color={genotypeSampleStats[entry[0]]}>
                    {entry[1]}
                  </Tag>
                ) : (
                  <Tag className={styles.detailTag} color={notAvailableTag[entry[1]]}>
                    N/A
                  </Tag>
                )}
              </div>
            ))}
        </Descriptions.Item>
        <Descriptions.Item label="Sex" span={2}>
          Customer{' '}
          {sample.sex ? (
            <Tag className={styles.sexTags} color={sexTags[sample?.sex]}>
              {sample?.sex?.toUpperCase()}
            </Tag>
          ) : (
            <Tag className={styles.sexTags} color={notAvailableTag[sample?.sex]}>
              N/A
            </Tag>
          )}
          <Divider />
          {analyses &&
            Object.entries(analyses).map((entry, i) => (
              <div key={entry[0]}>
                {entry[1].type.charAt(0).toUpperCase() + entry[1].type.slice(1)}
                {entry[1].sex ? (
                  <Tag className={styles.sexTags} color={sexTags[entry[1].sex]}>
                    {entry[1].sex?.toUpperCase()}
                  </Tag>
                ) : (
                  <Tag className={styles.sexTags} color={notAvailableTag[sample?.sex]}>
                    N/A
                  </Tag>
                )}

                {analyses.length - 1 !== i && <Divider />}
              </div>
            ))}
        </Descriptions.Item>
        <Descriptions.Item label="Failed snps" span={3}>
          {sampleDetail?.failed_snps?.length ? (
            Object.entries(sampleDetail.failed_snps).map((entry) => (
              <div key={entry[0]} className={styles.failedSnps}>
                <Tag color="red">{entry[1]}</Tag>
              </div>
            ))
          ) : (
            <Tag color="gold" className={styles.failedSnps}>
              N/A
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Comment" span={3}>
          <Paragraph
            editable={{
              onChange: (value) => handleChange(encodeURIComponent(value), 'comment'),
              tooltip: false,
            }}
          >
            {sample.comment}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
      <br></br>
      <SampleMatchTable sampleId={sample.id} />
    </Card>
  ) : (
    <Empty description={<span>Sample not found</span>}></Empty>
  )
}

export const SamplePage = connect(mapState)(SamplePageComponent)
