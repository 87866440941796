import React from 'react'
import { Button, Modal, Popconfirm, Tooltip } from 'antd'
import { UndoOutlined, TruckOutlined } from '@ant-design/icons'

interface DeliveryStatusBulkActionProps {
  handleBulkAction: any
  ids: string[]
  updateHandler: any
  hasSelected: boolean
  notUploaded: boolean
  notUploadedCases: any[]
  isSelectedCaseInView: boolean
}

export const DeliveryStatusBulkAction: React.FC<DeliveryStatusBulkActionProps> = ({
  handleBulkAction,
  ids,
  updateHandler,
  hasSelected,
  notUploaded,
  notUploadedCases,
  isSelectedCaseInView,
}) => {
  const handleConfirmMarkDelivered = () => {
    if (notUploaded) {
      Modal.confirm({
        title: `Mark as Delivered`,
        content: (
          <div>
            <p>
              The following analyses are not uploaded: <br />
              {notUploadedCases.map((caseObj) => (
                <>
                  <strong key={caseObj.caseId}>{caseObj.caseId}</strong>
                  <br />
                </>
              ))}
            </p>
            <p>Do you want to proceed with the delivery?</p>
          </div>
        ),
        onOk() {
          handleBulkAction('delivered', true, ids, updateHandler)
        },
      })
    } else {
      handleBulkAction('delivered', true, ids, updateHandler)
    }
  }

  return (
    <>
      {!isSelectedCaseInView ? (
        <>
          <Popconfirm
            title="Some cases are filtered out due to active filters or sorting. Are you sure you want to mark as delivered?"
            onConfirm={handleConfirmMarkDelivered}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Mark as delivered" placement="bottom">
              <Button disabled={!hasSelected} icon={<TruckOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Some cases are filtered out due to active filters or sorting. Are you sure you want to mark it as not delivered?"
            onConfirm={() => handleBulkAction('delivered', false, ids, updateHandler)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Mark as not delivered" placement="bottom">
              <Button disabled={!hasSelected} icon={<UndoOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
        </>
      ) : (
        <>
          <Popconfirm
            title="Are you sure you want to mark as delivered?"
            onConfirm={handleConfirmMarkDelivered}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Mark as delivered" placement="bottom">
              <Button disabled={!hasSelected} icon={<TruckOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to mark it as not delivered?"
            onConfirm={() => handleBulkAction('delivered', false, ids, updateHandler)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Mark as not delivered" placement="bottom">
              <Button disabled={!hasSelected} icon={<UndoOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
        </>
      )}
    </>
  )
}
