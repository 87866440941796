import React from 'react'
import { Table, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { formatDate, sortDate } from '../../Common/services/helpers/helpers'
import styles from './PlatesTable.module.css'
import { Plate } from '../interfaces'

interface PlatesTableProps {
  plates: Plate[] | undefined
  hideSignedBy?: boolean
}

export const StaticPlatesTable = ({ plates, hideSignedBy }: PlatesTableProps) => {
  const columns = [
    {
      title: 'Plate',
      dataIndex: 'plate_id',
      key: 'plate_id',
      render: (text, record) => <Link to={`/genotype/plates/${record.id}`}>{text}</Link>,
      sorter: (a, b) => a.plate_id.localeCompare(b.plate_id),
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.created_at, b.created_at),
    },
    {
      title: 'Status',
      dataIndex: 'signed_by',
      key: 'signed_by',
      width: 0,
      render: (name) => {
        return name ? <Tag color="geekblue">SIGNED</Tag> : <Tag>UNSIGNED</Tag>
      },
      filters: [
        {
          text: 'UNSIGNED',
          value: 'unsigned',
        },
        {
          text: 'SIGNED',
          value: 'signed',
        },
      ],
      onFilter: (value, record) =>
        record.signed_by ? (value === 'signed' ? true : false) : value === 'signed' ? false : true,
    },
    {
      title: 'Signed By',
      dataIndex: 'user',
      key: 'signed_by',
      hidden: hideSignedBy,
      render: (user) => <Link to={`/genotype/users/${user?.id}`}>{user?.name}</Link>,
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
    {
      title: 'Signed On',
      dataIndex: 'signed_at',
      key: 'signed_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.signed_at, b.signed_at),
    },
  ].filter((item) => !item.hidden)

  return (
    <Table
      dataSource={plates}
      columns={columns}
      pagination={false}
      rowKey={(plate) => plate.plate_id}
      tableLayout={'fixed'}
      rowClassName={(plate) =>
        plate.signed_by ? `${styles.customRow} ${styles.signed}` : styles.customRow
      }
    />
  )
}
