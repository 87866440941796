import React, { useState } from 'react'
import { Input, Table, Tag } from 'antd'
import { antdPresets } from '../../Common/styleHelpers'
import { ColumnType } from 'antd/lib/table'
import { UserProfile } from '../UserProfile'
import { Loading } from '../../Common/Loading'
import { driverRegex, pageLoadTimeout } from '../costants'
import { Drivers } from '../Drivers'

export const Projects = ({ issues, columnIssues, users }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { Search } = Input
  const [input, setInput] = useState('')
  const dataSource: any[] = []

  setTimeout(() => setIsLoading(false), pageLoadTimeout)

  issues.map((issue) => {
    columnIssues.forEach((column) => {
      if (column.issues.includes(issue.url)) {
        dataSource.push({ ...issue, category: column.column, columnIndex: column.columnIndex })
      }
    })
  })

  const assignCategoryTagColor = (category, columns = columnIssues) => {
    let categoryIndex = 0
    columns.forEach((column, index) => {
      if (column.column === category) categoryIndex = index
    })
    return antdPresets[categoryIndex]
  }

  const onSearch = (input) => setInput(input.toLowerCase())

  const filterBySearch = (data) =>
    data.filter((issue) => {
      let isAssigneeIncluded = false
      issue.assignees.forEach((assignee) => {
        if (assignee?.login.toLowerCase().includes(input)) isAssigneeIncluded = true
      })
      return (
        issue.title?.toLowerCase().includes(input) ||
        issue.body?.toLowerCase().includes(input) ||
        issue.category?.toLowerCase().includes(input) ||
        isAssigneeIncluded
      )
    })

  const columns: ColumnType<any>[] = [
    {
      title: 'Issue',
      dataIndex: 'title',
      key: 'title',
      render: (title, { html_url }) => (
        <a href={html_url} target="_blank" rel="noreferrer">
          {title}
        </a>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
      defaultSortOrder: 'ascend',
      render: (category) => <Tag color={assignCategoryTagColor(category)}>{category}</Tag>,
      sorter: (a, b) => a.columnIndex - b.columnIndex,
    },
    {
      title: 'Drivers',
      dataIndex: 'body',
      key: 'body',
      render: (body) => <Drivers body={body} users={users} />,
      sorter: (a, b) =>
        a.body?.match(driverRegex)?.[1].localeCompare(b.body?.match(driverRegex)?.[1]),
    },
    {
      title: 'Assignees',
      dataIndex: 'assignees',
      key: 'assignees',
      render: (assignees) => (
        <div>
          {assignees
            .sort((a, b) => a.login.localeCompare(b.login))
            .map((assignee) => (
              <UserProfile user={assignee} key={assignee.login} />
            ))}
        </div>
      ),
    },
  ]
  return isLoading ? (
    <Loading />
  ) : (
    <Table
      loading={!(dataSource.length > 0)}
      dataSource={filterBySearch(dataSource)}
      columns={columns}
      rowKey={'title'}
      pagination={false}
      title={() => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
        </div>
      )}
    />
  )
}
