import React from 'react'
import { Table, Tag } from 'antd'
import { formatDate, formatReads, sortDate } from '../../Common/services/helpers/helpers'
import { Link } from 'react-router-dom'
import { supportSystemTicketLink } from '../constants'

type Props = {
  samples: any[]
}

export const SamplesTable = ({ samples }: Props) => {
  const data = samples.map((sample) => {
    return {
      name: sample.internal_id,
      application: sample.application.tag,
      orderedAt: sample.ordered_at,
      ticketNumber: sample.original_ticket,
      reads: sample.reads,
      targetReads: sample.application.target_reads,
      sex: sample.sex,
      dataAnalysis: sample.workflow,
    }
  })

  const columns: any = [
    {
      title: 'Sample name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <Link to={`/genotype/samples/${name}`}>{name}</Link>,
    },
    {
      title: 'Ordered',
      dataIndex: 'orderedAt',
      key: 'orderedAt',
      render: (date: string) => formatDate(date),
      sorter: (a, b) => sortDate(a.orderedAt, b.orderedAt),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
    },
    {
      title: 'Data Analysis',
      dataIndex: 'dataAnalysis',
      key: 'dataAnalysis',
    },
    {
      title: 'Application',
      dataIndex: 'application',
      key: 'application',
    },
    {
      title: 'Reads',
      dataIndex: 'reads',
      key: 'reads',
      render: function Reads(reads: string, item) {
        return (
          !isNaN(parseInt(reads)) &&
          parseInt(reads) > 0 && (
            <div>
              {reads} <Tag>{formatReads(reads, item.targetReads)}%</Tag>
            </div>
          )
        )
      },
    },
    {
      title: 'Ticket',
      key: 'ticketNumber',
      dataIndex: 'ticketNumber',
      render: function Link(ticket) {
        return <a href={supportSystemTicketLink(ticket)}>{ticket}</a>
      },
    },
  ]

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} rowKey="name" bordered />
    </>
  )
}
