import React, { useEffect, useState } from 'react'
import { Table, Input } from 'antd'
import { getOwners } from '../services'
import { Link } from 'react-router-dom'

const { Search } = Input

export const OwnersTable = () => {
  const [owners, setOwners] = useState([])
  const [filteredOwners, setFilteredOwners] = useState(owners)
  const [pageCount, setPageCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getOwners().then((response) => {
      setOwners(response)
      setFilteredOwners(response)
      setPageCount(response.length)
      setIsLoading(false)
    })
  }, [])

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'username',
      render: (user_name: any, data: any) => (
        <Link to={`/atlas/owner/${data.email}`}>{user_name}</Link>
      ),
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Number of documents',
      dataIndex: 'document_count',
      key: 'document_count',
      sorter: (a, b) => a.document_count - b.document_count,
    },
  ]
  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total}`
  }

  const onSearch = (searchValue) => {
    setIsLoading(true)
    if (searchValue === '') {
      setFilteredOwners(owners)
      setIsLoading(false)
    } else {
      setFilteredOwners(
        owners.filter((owner: any) =>
          owner.user_name.toLowerCase().includes(searchValue.toLowerCase())
        )
      )
      setIsLoading(false)
    }
  }

  function onChange(pagination, filters, sorter, extra) {
    if (extra.action === 'paginate') {
      window.scrollTo({
        top: 0,
      })
    }
  }

  return (
    <>
      <Search
        allowClear
        placeholder={`Search Owner`}
        onSearch={onSearch}
        style={{ paddingBottom: 20 }}
      />
      <Table
        columns={columns}
        dataSource={filteredOwners}
        rowKey="user_name"
        pagination={{
          showTotal: showTotal,
          total: pageCount,
          defaultPageSize: 20,
        }}
        loading={isLoading}
        onChange={onChange}
      />
    </>
  )
}
