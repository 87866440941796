import React from 'react'
import { BarsOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { createNotification } from '../../../Common/services/helpers/helpers'

interface CopyCaseIdsProps {
  hasSelected: boolean
  caseIds: string[]
  isSelectedCaseInView: boolean
}

export const CopyCaseIds: React.FC<CopyCaseIdsProps> = ({
  hasSelected,
  caseIds,
  isSelectedCaseInView,
}) => {
  const handleCopy = () => {
    const caseIdsString = caseIds.join('\n')
    navigator.clipboard
      .writeText(caseIdsString)
      .then(() => {
        createNotification(
          'success',
          'Case IDs Copied',
          'The case IDs have been copied to the clipboard successfully.'
        )
      })
      .catch((err) => {
        console.error('Could not copy text: ', err)
      })
  }

  return (
    <Tooltip title="Copy case IDs" placement="bottom">
      {!isSelectedCaseInView ? (
        <Popconfirm
          title="Some cases are filtered out due to active filters or sorting. Continue?"
          onConfirm={handleCopy}
          okText="Yes"
          cancelText="No"
          disabled={!hasSelected}
        >
          <Button icon={<BarsOutlined />} disabled={!hasSelected} />
        </Popconfirm>
      ) : (
        <Button icon={<BarsOutlined />} onClick={handleCopy} disabled={!hasSelected} />
      )}
    </Tooltip>
  )
}
