import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { Column } from '@ant-design/charts'
import { Loading } from '../../Common/Loading'
import dayjs from 'dayjs'

export const TrendingGraph = ({ populateGraphMethod, additionalParameters }) => {
  const [data, setData] = useState<any[]>([])
  const dateFormat = 'YYYY-MM'
  const defaultMaxDate = dayjs()
  const defaultMinDate = dayjs().subtract(12, 'months')
  const [group] = additionalParameters

  useEffect(() => {
    updateGraph(defaultMinDate, defaultMaxDate)
  }, [])

  const updateGraph = (dateMin, dateMax) => {
    populateGraphMethod(
      dateMin.format(dateFormat),
      dateMax.format(dateFormat),
      ...additionalParameters
    ).then((response) => {
      setData(populateData(response))
    })
  }

  const populateData = (data) => {
    const flatData: any[] = []
    data.forEach((date) => {
      date.datapoints.forEach((datapoint) => {
        flatData.push({
          name: datapoint.tag || datapoint[group],
          label: date.date,
          size: datapoint.total,
        })
      })
    })
    return flatData.reverse()
  }

  const config = {
    data,
    xField: 'label',
    yField: 'size',
    seriesField: 'name',
    isGroup: 'true',
  }

  return (
    <>
      <DatePicker.RangePicker
        defaultValue={[defaultMinDate, defaultMaxDate]}
        picker="month"
        onChange={(value) => {
          if (value && value[0] && value[1]) {
            updateGraph(value[0], value[1])
          }
        }}
      />
      <br />
      <br />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore*/}
      {data?.length > 0 ? <Column {...config} /> : <Loading />}
    </>
  )
}
