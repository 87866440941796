import React, { useState } from 'react'
import { Modal, Tooltip } from 'antd'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'
import { getCaseDeliveryMessage } from '../../services'
import styles from '../EditableTable.module.css'
import { createNotification } from '../../../Common/services/helpers/helpers'

const CopyMessageAction = ({ record }) => {
  const [copied, setCopied] = useState(false)

  const getMessage = async () => {
    const deliveryMessage = await getCaseDeliveryMessage(record.case_id)
    await navigator.clipboard.writeText(deliveryMessage.message)
    createNotification(
      'success',
      'Message copied',
      'The delivery message has been copied to the clipboard'
    )
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  const handleCopy = async () => {
    if (record.uploaded_at) {
      getMessage()
    } else {
      Modal.confirm({
        title: 'Analyses not uploaded',
        content: 'Do you want to proceed with copying the delivery message?',
        async onOk() {
          getMessage()
        },
      })
    }
  }

  return (
    <div onClick={handleCopy} className={styles.actionIcons}>
      <Tooltip placement="bottom" title={copied ? 'Copied!' : 'Copy delivery message'}>
        {copied ? (
          <CheckOutlined style={{ color: 'green', fontSize: '18px' }} />
        ) : (
          <CopyOutlined style={{ fontSize: '18px' }} />
        )}
      </Tooltip>
    </div>
  )
}

export default CopyMessageAction
