import React, { useEffect } from 'react'
import { Result } from 'antd'
import { useHistory } from 'react-router-dom'
import mainStyles from '../../App.module.css'

export const SomethingWentWrongPage = () => (
  <div className={mainStyles.content}>
    <Result status="error" title="Something went wrong" />
  </div>
)
