import React from 'react'
import { Button, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { getCaseDeliveryMessage } from '../services'
import { isAnalysisDeliverable } from '../utils'
import { createNotification } from '../../Common/services/helpers/helpers'

export const AnalysisDeliveryMessageBtn = ({ analysis }) => {
  const handleOnClick = async () => {
    const deliveryMessage = await getCaseDeliveryMessage(analysis?.case_id)
    await navigator.clipboard.writeText(deliveryMessage.message)
    createNotification(
      'success',
      'Message copied',
      'The delivery message has been copied to the clipboard successfully.'
    )
  }

  return isAnalysisDeliverable(analysis) ? (
    <Tooltip title="Copy delivery message">
      <Button
        onClick={handleOnClick}
        icon={<CopyOutlined style={{ fontSize: '18px' }} />}
        type="text"
      />
    </Tooltip>
  ) : null
}
