export const isAnalysisDeliverable = (analysis) => {
  return (
    analysis?.status === 'completed' &&
    analysis.workflow !== 'RSYNC' &&
    analysis.workflow !== 'DEMULTIPLEX' &&
    analysis.workflow !== 'SPRING'
  )
}

export const getWorkflowFromURL = (pathname) => {
  const pathSegments = pathname.split('/')
  const lastSegment = pathSegments[pathSegments.length - 1]
  return lastSegment === 'all-analyses' || lastSegment === 'all-orders' ? '' : lastSegment
}
