import React from 'react'
import { driverRegex } from './costants'
import { UserProfile } from './UserProfile'

export const Drivers = ({ body, users }) => {
  if (body?.match(driverRegex)?.length > 1) {
    const drivers = body
      ?.match(driverRegex)?.[1]
      .split(',')
      .filter((driver) => driver.length > 2)
      .map((driver) => driver.replace('@', '').trim())
    return (
      <div style={{ display: 'flex', gap: 3 }}>
        {drivers.map((driver) => {
          const assignee = users.find((assignee) => assignee.login === driver)
          return assignee ? (
            <UserProfile key={driver} user={assignee} />
          ) : (
            <div key={driver}>{driver}</div>
          )
        })}
      </div>
    )
  }
  return <div />
}
