import React from 'react'
import { Input } from 'antd'

export default function SearchBar({ searchData }) {
  return (
    <div style={{ width: '100%' }}>
      <Input.Search placeholder="Search..." enterButton onSearch={searchData} />
    </div>
  )
}
