import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getUDFs, getRules, getSteps, getWorkflows } from '../services'
import { getTypeTagColor } from '../../Common/styleHelpers'
import { formatDate, OpenNotification } from '../../Common/services/helpers/helpers'
import styles from './StepsTable.module.css'
import { PlusCircleOutlined } from '@ant-design/icons'

export const StepsTable = () => {
  const [steps, setSteps] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState([])
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'step_type',
    'sample_id',
    'workflow',
    'lims_step_name',
  ])
  const [uDFs, setUDFs] = useState<any[]>([])
  const [currentUDF, setCurrentUDF] = useState<any>()
  const [rules, setRules] = useState<any>([])
  const [appliedUDFRules, setAppliedUDFRules] = useState<any>([])
  const { Text } = Typography
  const { Panel } = Collapse
  const [filterForm] = Form.useForm()
  const [ruleForm] = Form.useForm()
  const { Option } = Select

  useEffect(() => {
    getSteps({ page_size: 150 })
      .then((response) => {
        setSteps(response)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))

    getRules().then((response) => setRules(response))

    getWorkflows().then((response) => {
      setOptions(
        response.map((workflow: any) => {
          return {
            value: workflow._id,
            label: workflow._id,
            children: workflow?.step_types?.map((step) => {
              return {
                value: step,
                label: step,
              }
            }),
          }
        })
      )
    })
  }, [])

  const addRule = (rule) => {
    const selectedUDF = uDFs.filter((udf) => udf.udf === rule.uDF)[0]
    setAppliedUDFRules([
      ...appliedUDFRules,
      {
        udf_name: rule.uDF,
        udf_rule: rule.queryRule,
        udf_readable_rule: rule.queryRule,
        udf_value: rule.value,
        udf_query_type: selectedUDF?.type,
        udf_type: selectedUDF?.udf_type,
      },
    ])
    ruleForm.resetFields()
  }

  const onChangeColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues)
  }

  const populateUDF = (selection) => {
    if (selection.length > 1) {
      getUDFs(selection).then((response) => setUDFs(response))
    } else {
      setUDFs([])
    }
  }

  const onSearch = (values) => {
    setIsLoading(true)
    const ruleBody = { page_size: 150 }
    Object.entries(applyWorkflowFilter(values)).forEach((value: any) => {
      if (value[1]) {
        ruleBody[value[0]] = value[1]
      }
    })
    if (appliedUDFRules.length > 0) {
      ruleBody['udf_filters'] = appliedUDFRules.map((rule) => {
        rule.udf_rule = rules[rule.udf_query_type].filter(
          (comp) => rule.udf_readable_rule === comp.readable
        )[0].mongo_rule
        return rule
      })
    }
    getSteps(ruleBody)
      .then((response) => {
        setSteps(response)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        OpenNotification({
          type: 'error',
          message: 'Something was wrong with your request',
          description: error.response.data.detail[0].msg,
        })
      })
  }

  const filters = [
    {
      title: 'Sample id',
      key: 'sample_id',
      component: <Input style={{ width: 120 }} />,
    },
    {
      title: 'Well',
      key: 'well_position',
      component: (
        <Select showSearch style={{ width: 90 }} optionFilterProp="children">
          {plateWells().map((well) => (
            <Option value={well} key={well}>
              {well}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Artifact name',
      key: 'artifact_name',
      component: <Input data-testid={'artifact-name-input'} style={{ width: 120 }} />,
    },
    {
      title: 'Container name',
      key: 'container_name',
      component: <Input style={{ width: 120 }} />,
    },
    {
      title: 'Container id',
      key: 'container_id',
      component: <Input style={{ width: 120 }} />,
    },
    {
      title: 'Container type',
      key: 'container_type',
      component: (
        <Select allowClear style={{ width: 120 }}>
          <Option value="96 well plate">96 wells plate</Option>
          <Option value="Tube">Tube</Option>
        </Select>
      ),
    },
    {
      title: 'Index name',
      key: 'index_name',
      component: <Input style={{ width: 120 }} />,
    },
  ]

  const columns = [
    {
      title: 'Step type',
      dataIndex: 'step_type',
      key: 'step_type',
    },
    {
      title: 'Sample id',
      dataIndex: 'sample_id',
      key: 'sample_id',
    },
    {
      title: 'Workflow',
      dataIndex: 'workflow',
      key: 'workflow',
      render: (workflow) => <Tag color={getTypeTagColor(workflow)}>{workflow}</Tag>,
    },
    {
      title: 'Lims step name',
      dataIndex: 'lims_step_name',
      key: 'lims_step_name',
    },
    {
      title: 'Prep id',
      dataIndex: 'prep_id',
      key: 'prep_id',
    },
    {
      title: 'Well',
      dataIndex: 'well_position',
      key: 'well_position',
    },
    {
      title: 'Artifact name',
      dataIndex: 'artifact_name',
      key: 'artifact_name',
    },
    {
      title: 'Container name',
      dataIndex: 'container_name',
      key: 'container_name',
    },
    {
      title: 'Container id',
      dataIndex: 'container_id',
      key: 'container_id',
    },
    {
      title: 'Container type',
      dataIndex: 'container_type',
      key: 'container_type',
    },
    {
      title: 'Index name',
      dataIndex: 'index_name',
      key: 'index_name',
    },
    {
      title: 'Nr samples',
      dataIndex: 'nr_samples_in_pool',
      key: 'nr_samples_in_pool',
    },
    {
      title: 'Process UDF',
      dataIndex: 'process_udfs',
      key: 'process_udfs',
      render: (udfs) => (
        <div>
          {Object.entries(udfs).map((item: any, i) => (
            <div key={`${item[0]} + ${item[1]} + ${i} `}>
              {item[0]}:<Text strong>{item[1]}</Text>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Artifact UDF',
      dataIndex: 'artifact_udfs',
      key: 'artifact_udfs',
      render: (udfs) => (
        <div>
          {Object.entries(udfs).map((item: any, i) => (
            <div key={`${item[0]} + ${item[1]} + ${i} `}>
              {item[0]}:<Text strong>{item[1]}</Text>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Date run',
      dataIndex: 'date_run',
      key: 'date_run',
      render: (date) => formatDate(date),
    },
  ]

  return (
    <>
      <>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Filters" key="1">
            Workflow / Step type:{' '}
            <Cascader
              options={options}
              changeOnSelect
              style={{ width: 380 }}
              onChange={populateUDF}
            />
            <Divider />
            <Form name="artifactRule" onFinish={addRule} form={ruleForm}>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name={'uDF'}
                    key={'uDF'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={'UDFs'}
                      style={{ width: 400 }}
                      disabled={!uDFs}
                      onSelect={(value) => {
                        setCurrentUDF(uDFs.filter((udf) => udf.udf === value)[0])
                      }}
                      notFoundContent={
                        <div>
                          No UDFs found. Select Workflow / Step type to see the available UDFs
                        </div>
                      }
                    >
                      {uDFs?.map((udf) => (
                        <Option key={udf.udf} value={udf.udf}>
                          <div>
                            {udf.udf_type === 'artifact' ? (
                              <Tag color={'purple'}>{'art'}</Tag>
                            ) : (
                              <Tag color={'magenta'}>{'proc'}</Tag>
                            )}{' '}
                            {udf.udf}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={'queryRule'}
                    key={'queryRule'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: 130 }}
                      disabled={!currentUDF}
                      placeholder={'rule'}
                    >
                      {currentUDF &&
                        rules[currentUDF.type].map((rule) => (
                          <Option key={rule.mongo_rule} value={rule.readable}>
                            {rule.readable}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={'value'}
                    key={'value'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={'value'} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button htmlType="submit" shape="circle" icon={<PlusCircleOutlined />} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>Applied rules: </Col>
                <Col>
                  {appliedUDFRules?.map(({ udf_name, udf_readable_rule, udf_value }) => (
                    <Tag key={udf_name} color={'green'}>
                      {udf_name} {udf_readable_rule} {udf_value}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <br />
              <Button
                type={'default'}
                onClick={() => {
                  ruleForm.resetFields()
                  setAppliedUDFRules([])
                }}
              >
                Clear UDFs rules
              </Button>
            </Form>
            <Divider />
            <Form name="nest-messages" onFinish={onSearch} form={filterForm}>
              <Row>
                {filters.map((filter) => (
                  <Form.Item
                    hasFeedback
                    name={filter.key}
                    label={filter.title}
                    key={filter.key}
                    className={styles.input}
                  >
                    {filter.component}
                  </Form.Item>
                ))}
              </Row>
              <Row>
                <Button
                  style={{ marginRight: 15 }}
                  type={'default'}
                  onClick={() => {
                    filterForm.resetFields()
                  }}
                >
                  Clear filters
                </Button>
                <Divider />
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Panel>
        </Collapse>
        <br />
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Select displayed columns" key="1">
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={onChangeColumnSelection}
              defaultValue={selectedColumns}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {columns.map((column) => (
                  <div key={column.key} style={{ padding: 5 }}>
                    <Checkbox value={column.key}>{column.title}</Checkbox>
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          </Panel>
        </Collapse>
      </>
      <br />
      <Table
        dataSource={steps}
        columns={columns.filter((column) => selectedColumns.includes(column.key))}
        scroll={{ x: 1500 }}
        loading={isLoading}
        pagination={false}
        rowKey={'_id'}
      />
    </>
  )
}

const plateWells = (plateRows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'], plateColumns = 12) => {
  const allWells: string[] = []
  Array.from(Array(plateColumns).keys()).forEach((column) => {
    plateRows.forEach((row) => allWells.push(`${row}:${column + 1}`))
  })
  return allWells
}

const applyWorkflowFilter = (values) => {
  if (values.workflow) {
    const workflow = values.workflow
    values.workflow = workflow[0]
    if (workflow[1]) {
      values.step_type = workflow[1]
    }
  }
  return values
}
