import React, { useState } from 'react'
import styles from './Pyramid.module.css'
import { Card, Divider, Tag, Tooltip } from 'antd'
import { UserProfile } from '../UserProfile'
import { Drivers } from '../Drivers'
import { areaLabel, pageLoadTimeout } from '../costants'
import { Loading } from '../../Common/Loading'

export const Pyramid = ({ issues, columnIssues, users }) => {
  const allPriorityLabels: any = []
  const [isLoading, setIsLoading] = useState(true)
  setTimeout(() => setIsLoading(false), pageLoadTimeout)
  const localIssues = issues
    .filter((issue) => {
      return !!issue.labels.find((label) => label.name === 'Prio 1')
    })
    .filter((issue) => {
      let isIssueCurrent = false
      columnIssues.forEach((columnIssue) => {
        if (columnIssue.issues.includes(issue.url)) isIssueCurrent = true
      })
      return isIssueCurrent
    })

  let orderedPriorityLabels: any = localIssues.map((issue) =>
    issue.labels.map((label) => {
      if (label.name.length === 1) {
        allPriorityLabels.push(label.name)
      }
    })
  )

  const priorityOrder = (allPriorityLabels) => {
    allPriorityLabels.sort()
    const uniquePriorityLabels = new Set(allPriorityLabels)
    orderedPriorityLabels = Array.from(uniquePriorityLabels)
    return orderedPriorityLabels
  }

  priorityOrder(allPriorityLabels)

  const sameLabelIssues = (priorityLabel, issues) =>
    issues.filter((issue) => {
      let hasRightLabel = false
      issue.labels.forEach((label) => {
        if (label.name === priorityLabel) hasRightLabel = true
      })
      return hasRightLabel
    })

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.issuesContainer}>
      {orderedPriorityLabels.map((priorityLabel) => (
        <div key={priorityLabel} className={styles.issue}>
          {sameLabelIssues(priorityLabel, localIssues).map((issue) => (
            <Card style={{ textAlign: 'center' }} key={issue.id}>
              {issue.labels.map((label) => {
                return label.name.includes(areaLabel) ? (
                  <Tag color={`#${label.color}`} key={label.name}>
                    {label.name.replace(areaLabel, '')}
                  </Tag>
                ) : null
              })}{' '}
              <a href={issue?.html_url}>{issue.title}</a>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Divider plain>
                  <Drivers body={issue.body} users={users} />
                </Divider>
                <div>
                  {issue.assignees.map((assignee) => (
                    <UserProfile key={assignee.login} user={assignee} />
                  ))}
                </div>
              </div>
            </Card>
          ))}
        </div>
      ))}
    </div>
  )
}
