import React, { useState } from 'react'
import { Flex, Radio, Table, Tag, Typography } from 'antd'
import styles from './AnalysisSummary.module.css'
import { getStatusTagColor } from '../../Common/styleHelpers'

const { Text } = Typography
const filterJobs = (jobs, jobStatus) => jobs?.filter((job) => job?.status?.includes(jobStatus))
const hasJobWithStatus = (jobs, jobStatus) => jobs?.some((job) => job?.status?.includes(jobStatus))

const getAllJobs = (analysis) => {
  const analysisJobs = analysis?.jobs || []
  const uploadJobs = analysis?.upload_jobs || []
  return [...analysisJobs, ...uploadJobs]
}

const getJobsToDisplay = (analysis, jobType) => {
  const allJobs = getAllJobs(analysis)

  if (allJobs.length == 0) return []
  if (jobType === 'all') return allJobs
  if (jobType === 'upload') return analysis?.upload_jobs
  return filterJobs(allJobs, jobType)
}

const hasUploadJobs = (analysis) => {
  return analysis?.upload_jobs?.length > 0
}

const getJobOptions = (analysis) => {
  const allJobs = getAllJobs(analysis)

  const hasCompletedJobs = hasJobWithStatus(allJobs, 'completed')
  const hasFailedJobs = hasJobWithStatus(allJobs, 'failed')
  const hasRunningJobs = hasJobWithStatus(allJobs, 'running')
  const hasPendingJobs = hasJobWithStatus(allJobs, 'pending')
  const hasCancelledJobs = hasJobWithStatus(allJobs, 'cancelled')
  const hasUploads = hasUploadJobs(analysis)
  const hasJobs = allJobs.length > 0

  return [
    ...(hasJobs ? [{ label: 'ALL JOBS', value: 'all' }] : []),
    ...(hasCompletedJobs ? [{ label: 'COMPLETED', value: 'completed' }] : []),
    ...(hasPendingJobs ? [{ label: 'PENDING', value: 'pending' }] : []),
    ...(hasRunningJobs ? [{ label: 'RUNNING', value: 'running' }] : []),
    ...(hasFailedJobs ? [{ label: 'FAILED', value: 'failed' }] : []),
    ...(hasUploads ? [{ label: 'UPLOAD JOBS', value: 'upload' }] : []),
    ...(hasCancelledJobs ? [{ label: 'CANCELLED', value: 'cancelled' }] : []),
  ]
}
const columns = [
  {
    title: 'Slurm ID',
    dataIndex: 'slurm_id',
    key: 'slurm_id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'Elapsed',
    dataIndex: 'elapsed',
    key: 'elapsed',
    render: (elapsed) => (elapsed ? <Text>{elapsed} min</Text> : <Text>-</Text>),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => <Tag color={getStatusTagColor(status)}> {status?.toUpperCase()} </Tag>,
  },
]

const JobsTable = ({ analysis }) => {
  const [selectedJobStatus, setSelectedJobStatus] = useState('all')
  const selectedJobs = getJobsToDisplay(analysis, selectedJobStatus)
  const options = getJobOptions(analysis)
  return (
    <>
      {selectedJobs.length > 0 ? (
        <Flex vertical align="center">
          <Radio.Group
            className={styles.jobsStatusRadio}
            options={options}
            onChange={(e) => setSelectedJobStatus(e.target.value)}
            value={selectedJobStatus}
            optionType="button"
            buttonStyle="solid"
          />
          <Table
            dataSource={selectedJobs}
            columns={columns}
            style={{ maxHeight: '200px', overflowX: 'hidden', width: 'auto' }}
            pagination={false}
            rowKey={(record) => record.id}
          />
        </Flex>
      ) : (
        <Text>No jobs found</Text>
      )}
    </>
  )
}

export default JobsTable
