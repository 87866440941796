import React, { useEffect, useState } from 'react'
import { getSamples } from '../services'
import { SamplesTable } from '../SamplesTable/SamplesTable'
import { Button, Card, Checkbox, Form, Input, Typography } from 'antd'

export const SamplesPage = () => {
  const amountSamplesDisplayed = 100
  const [samples, setSamples] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { Text } = Typography
  const [form] = Form.useForm()

  useEffect(() => {
    populatePGE(null, false, null)
  }, [])

  const populatePGE = (sampleId, isIncomplete, plateId) => {
    setIsLoading(true)
    getSamples({
      skip: 0,
      limit: amountSamplesDisplayed,
      sample_id: sampleId,
      incomplete: isIncomplete,
      plate_id: plateId,
    })
      .then((response) => {
        setSamples(response)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const onSearch = ({ sampleId, isIncomplete, plateId }) =>
    populatePGE(sampleId, isIncomplete, plateId)

  return (
    <>
      <Card>
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onSearch}
          style={{ display: 'flex', gap: 20 }}
        >
          <Form.Item name={`sampleId`} label={`Sample id`}>
            <Input allowClear style={{ width: 130 }} placeholder={`Sample id`} />
          </Form.Item>
          <Form.Item name={`plateId`} label={`Plate id`}>
            <Input allowClear style={{ width: 130 }} placeholder={`Plate id`} />
          </Form.Item>
          <Form.Item name={`isIncomplete`} label={`Incomplete`} valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              form.resetFields()
            }}
          >
            Clear
          </Button>
        </Form>
        <Text type="secondary">Only the top {amountSamplesDisplayed} results are displayed</Text>
      </Card>
      <SamplesTable samples={samples} isLoading={isLoading} hideColumns={[]} />
    </>
  )
}
