import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../domain/rootReducer'
import { useHistory } from 'react-router-dom'
import mainStyles from '../../../App.module.css'
import styles from './PlatePage.module.css'
import { getPlate, signOffPlate, getPlateAnalyses } from '../services'
import { Button, Card, Descriptions, Form, Input, InputNumber, message, Spin, Tag } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { genotypeSampleStatus } from '../../Common/styleHelpers'
import { createErrorNotification, formatDate } from '../../Common/services/helpers/helpers'
import { Loading } from '../../Common/Loading'
import { Plate } from '../interfaces'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SamplesTable } from '../SamplesTable/SamplesTable'
import { getAtlasVersion, getDocumentPath } from '../../Atlas/services'

const mapState = ({ settings }: RootState) => ({ settings } as const)

const PlateComponent = ({ settings }) => {
  const [plate, setPlate] = useState<Plate>()
  const [analyses, setAnalyses] = useState<any[]>([])
  const [samplesAmount, setSamplesAmount] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [passedSamples, setPassedSamples] = useState<number>(0)
  const [failedSamples, setFailedSamples] = useState<number>(0)
  const [cancelledSamples, setCancelledSamples] = useState<number>(0)
  const [comments, setComments] = useState<number>(0)
  const { pathname } = useLocation()
  const plateId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
  const [form] = Form.useForm()
  const history = useHistory()
  const limit = 65
  const genotypeSignDocumentTitle = 'Genotyping concordance'
  const getDocumentTitleFromURLRegex = /[^/]*$/

  useEffect(() => {
    getAtlasVersion()
      .then((version) => form.setFieldValue('methodVersion', version))
      .catch((error) => createErrorNotification(error))
    getDocumentPath(genotypeSignDocumentTitle)
      .then((document) =>
        form.setFieldValue('methodDocument', document.match(getDocumentTitleFromURLRegex)[0])
      )
      .catch((error) => createErrorNotification(error))
    getPlate(plateId)
      .then((response) => {
        fillStats(response.analyses)
        setPlate(response)
        setSamplesAmount(response.analyses?.length)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
    loadMoreData()
  }, [])

  const loadMoreData = () => {
    if (!isInfiniteLoading) {
      setIsInfiniteLoading(true)
      getPlateAnalyses(plateId, analyses?.length, limit)
        .then((response) => {
          setAnalyses([...analyses, ...response])
          setIsInfiniteLoading(false)
          setHasMore(response.length === limit)
        })
        .catch(() => {
          setIsInfiniteLoading(false)
        })
    }
  }

  const fillStats = (analyses) => {
    let nFailed = 0
    let nCancelled = 0
    let nPassed = 0
    let nComments = 0
    analyses.map((analysis) => {
      if (analysis.sample?.comment) nComments++

      switch (analysis.sample.status) {
        case 'fail':
          nFailed++
          return
        case 'cancel':
          nCancelled++
          return
        case 'pass':
          nPassed++
          return
      }
    })
    setFailedSamples(nFailed)
    setCancelledSamples(nCancelled)
    setPassedSamples(nPassed)
    setComments(nComments)
  }

  const onFinish = (values: any) => {
    setIsLoading(true)
    signOffPlate(plateId, values.methodDocument, values.methodVersion)
      .then(() => {
        setIsLoading(false)
        message.success(`Plate ${plate?.plate_id} has been signed off`)
        history.push('/genotype/plates')
        window.scrollTo({
          top: 0,
        })
      })
      .catch((error) => {
        createErrorNotification(error)
        setIsLoading(false)
      })
  }

  return (
    <div className={mainStyles.content}>
      <Form layout="inline" onFinish={onFinish} form={form}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {plate && (
              <>
                <Card
                  className={
                    plate.signed_by
                      ? settings.themeMode
                        ? styles.signedDark
                        : styles.signed
                      : undefined
                  }
                >
                  <Descriptions
                    title={
                      <>
                        {plate.plate_id}{' '}
                        {plate.signed_by ? <Tag color="geekblue">SIGNED</Tag> : <Tag>UNSIGNED</Tag>}
                      </>
                    }
                  >
                    <Descriptions.Item label="Created at">
                      {formatDate(plate.created_at)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Signed on">
                      {formatDate(plate.signed_at)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Signed by">
                      <Link to={`/genotype/users/${plate.user?.id}`}>{plate.user?.name}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Failed samples">
                      {failedSamples > 0 ? (
                        <Tag color={genotypeSampleStatus.fail} data-testid={'fail-stats'}>
                          {failedSamples}/{samplesAmount}
                        </Tag>
                      ) : (
                        <div>
                          {failedSamples}/{samplesAmount}
                        </div>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled samples">
                      {cancelledSamples > 0 ? (
                        <Tag color={genotypeSampleStatus.cancel} data-testid={'cancel-stats'}>
                          {cancelledSamples}/{samplesAmount}
                        </Tag>
                      ) : (
                        <div>
                          {cancelledSamples}/{samplesAmount}
                        </div>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Passed samples">
                      {passedSamples > 0 ? (
                        <Tag color={genotypeSampleStatus.pass} data-testid={'pass-stats'}>
                          {passedSamples}/{samplesAmount}
                        </Tag>
                      ) : (
                        <div>
                          {passedSamples}/{samplesAmount}
                        </div>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Comments">
                      {comments > 0 ? (
                        <Tag color={'geekblue'} data-testid={'comment-stats'}>
                          {comments}/{samplesAmount}
                        </Tag>
                      ) : (
                        <div>
                          {comments}/{samplesAmount}
                        </div>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      label="Method document "
                      name="methodDocument"
                      rules={[{ required: true, message: "'Atlas version' is required" }]}
                    >
                      <Input style={{ width: '210px' }} />
                    </Form.Item>
                    <Form.Item
                      label="Method version (Atlas)"
                      name="methodVersion"
                      rules={[{ required: true, message: "'Method version' is required" }]}
                    >
                      <InputNumber style={{ width: '70px', marginRight: '30px' }} />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      <Button type="primary" htmlType="submit">
                        Sign off as {settings?.user?.email || 'N/A'}
                      </Button>
                    </Form.Item>
                  </div>
                </Card>
                <p></p>
                <InfiniteScroll
                  dataLength={analyses?.length}
                  next={loadMoreData}
                  hasMore={hasMore}
                  loader={
                    <Spin
                      style={{
                        width: '100%',
                        padding: 10,
                      }}
                    />
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <SamplesTable
                    samples={analyses}
                    isLoading={isLoading}
                    hideColumns={['analyses']}
                  />
                </InfiniteScroll>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  )
}

export const PlatePage = connect(mapState)(PlateComponent)
