import React, { useState } from 'react'
import { Button, Card, message, Typography, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { StaticPlatesTable } from '../PlatesTable/StaticPlatesTable'
import { getPlate, uploadPlate } from '../services'
import { Loading } from '../../Common/Loading'

const { Title } = Typography

export const PlateUpload = () => {
  const [data, setData] = useState<any[]>([])
  const [duplicatedFile, setDuplicatedFile] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const uploadXlsx = async ({ file }) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    uploadPlate(formData)
      .then((response) => {
        setDuplicatedFile(false)
        message.success('Upload successful')
        setData([response])
        setIsLoading(false)
      })
      .catch((response) => {
        if (response.response?.status === 409) {
          setDuplicatedFile(true)
          getPlate(response.response.data.detail).then((response) => {
            setData([response])
          })
        }
        setIsLoading(false)
      })
  }

  return (
    <Card>
      {isLoading && <Loading />}
      <Upload
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        maxCount={1}
        customRequest={uploadXlsx}
        showUploadList={false}
        data-testid="upload-plate"
      >
        <Button icon={<UploadOutlined />}>Upload Plate</Button>
      </Upload>
      {duplicatedFile && (
        <Title level={2} style={{ marginTop: '30px' }}>
          Plate already exists
        </Title>
      )}
      {data?.length > 0 && data[0].plate_id && (
        <StaticPlatesTable plates={data} hideSignedBy={false} />
      )}
    </Card>
  )
}
