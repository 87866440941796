import React, { useEffect, useState } from 'react'
import { Button, Card, DatePicker, Form, Select, Table } from 'antd'
import { analysisTypes, getMatches } from '../services'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

export const SampleMatchTable = ({ sampleId }) => {
  const [matches, setMatches] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showTable, setShowTable] = useState<boolean>(false)
  const startDate = dayjs().subtract(65, 'days')

  const fillMatches = ({ analysisType, comparisonSet, dateMin, dateMax }) => {
    setIsLoading(true)
    setShowTable(true)
    getMatches(
      sampleId,
      analysisType,
      comparisonSet,
      dayjs(dateMin).format('YYYY-MM-DD'),
      dayjs(dateMax).format('YYYY-MM-DD')
    )
      .then((response) => {
        setMatches(response)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  useEffect(() => {
    setMatches(undefined)
    setShowTable(false)
  }, [sampleId])

  const columns = [
    {
      title: 'Sample Id',
      dataIndex: 'sample_id',
      key: 'sample_id',
      render: (sample) => <Link to={`/genotype/samples/${sample}`}>{sample}</Link>,
      sorter: (a, b) => a?.sample_id?.localeCompare(b?.sample_id),
    },
    {
      title: 'Matches',
      dataIndex: 'match_results',
      key: 'match',
      render: (results) => <div>{results?.match}</div>,
      sorter: (a, b) => a?.match_results?.match - b?.match_results?.match,
    },
    {
      title: 'Mismatches',
      dataIndex: 'match_results',
      key: 'mismatch',
      render: (results) => <div>{results?.mismatch}</div>,
      sorter: (a, b) => a?.match_results?.mismatch - b?.match_results?.mismatch,
    },
    {
      title: 'Unknown',
      dataIndex: 'match_results',
      key: 'unknown',
      render: (results) => <div>{results.unknown}</div>,
      sorter: (a, b) => a?.match_results?.unknown - b?.match_results?.unknown,
    },
  ]

  return (
    <Card>
      <Form layout="inline" size={'small'} onFinish={fillMatches}>
        <Form.Item
          label="Analysis type"
          name={'analysisType'}
          initialValue={analysisTypes[0].id}
          rules={[
            {
              required: true,
              message: 'Please input the analysis type',
            },
          ]}
        >
          <Select>
            {analysisTypes.map((option) => (
              <Select.Option value={option.id} key={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Comparison set"
          name={'comparisonSet'}
          initialValue={analysisTypes[0].id}
          rules={[
            {
              required: true,
              message: 'Please input the comparison set',
            },
          ]}
        >
          <Select>
            {analysisTypes.map((option) => (
              <Select.Option value={option.id} key={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="From"
          name={'dateMin'}
          initialValue={startDate}
          rules={[
            {
              required: true,
              message: 'Please input date from',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="To"
          name={'dateMax'}
          initialValue={dayjs()}
          rules={[
            {
              required: true,
              message: 'Please input date to',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'}>
            Find matches
          </Button>
        </Form.Item>
      </Form>
      <br></br>
      {showTable && (
        <Table
          columns={columns}
          dataSource={matches}
          pagination={false}
          rowKey={'sample_id'}
          loading={isLoading}
        />
      )}
    </Card>
  )
}
