import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { OpenNotification } from './services/helpers/helpers'

export const signIn = () => {
  const provider = new firebase.auth.GithubAuthProvider()
  provider.addScope('repo')

  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        resolve(result)
      })
      .catch((error) => {
        OpenNotification({
          type: 'error',
          message: 'Something went wrong',
          description: 'Could not login with GitHub',
        })
        reject(error)
      })
  })
}

export const anonymousFirebaseSignIn = () => {
  firebase
    .auth()
    .signInAnonymously()
    .catch((error) => console.log(error))
}
