import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Card, Descriptions } from 'antd'
import { getUser } from '../services'
import { StaticPlatesTable } from '../PlatesTable/StaticPlatesTable'
import { Loading } from '../../Common/Loading'
import { User } from '../interfaces'

export const UserPage = ({ match }: RouteComponentProps) => {
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    params: { id },
  }: any = match

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getUser(id)
        .then((response: User) => {
          setUser(response)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }, [id])

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <Descriptions title={user?.name}>
          <Descriptions.Item label="">{user?.email}</Descriptions.Item>
          <Descriptions.Item label="Id">{user?.id}</Descriptions.Item>
        </Descriptions>
      </Card>
      <p></p>
      <StaticPlatesTable plates={user?.plates} hideSignedBy={true} />
    </>
  )
}
