import React, { useEffect, useState } from 'react'
import { Table, Input, Tag } from 'antd'
import { sortDate } from '../../Common/services/helpers/helpers'
import { getDocuments } from '../services'
import { Link } from 'react-router-dom'
import { AtlasTagColors } from '../../Common/styleHelpers'

const { Search } = Input

export const DocumentsTable = () => {
  const [documents, setDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const [pageCount, setPageCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getDocuments().then((response) => {
      setDocuments(response)
      setFilteredDocuments(response)
      setPageCount(response?.length)
      setIsLoading(false)
    })
  }, [])

  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: any, data: any) => (
        <a href={data.url} target="_blank" rel="noreferrer">
          {title}
        </a>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Owner',
      dataIndex: 'user_name',
      key: 'username',
      render: (user_name: any, data: any) => (
        <Link to={`/atlas/owner/${data.owner}`}>{user_name}</Link>
      ),
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
    },
    {
      title: 'Last Revision',
      dataIndex: 'revision_date',
      key: 'revision_date',
      sorter: (a, b) => sortDate(a.revision_date, b.revision_date),
    },
    {
      title: 'Next Revision',
      dataIndex: 'yearly_revision_date',
      key: 'yearly_revision_date',
      sorter: (a, b) => sortDate(a.yearly_revision_date, b.yearly_revision_date),
    },
    {
      title: 'Status',
      dataIndex: 'is_yearly_revision_date_expired',
      key: 'is_yearly_revision_date_expired',
      render: (is_yearly_revision_date_expired: boolean) =>
        is_yearly_revision_date_expired ? (
          <Tag color={AtlasTagColors.expired}>Needs Revision</Tag>
        ) : (
          <Tag color={AtlasTagColors.upToDate}>Up To Date</Tag>
        ),
      filters: [
        {
          text: 'Needs Revision',
          value: 'true',
        },
        {
          text: 'Up To Date',
          value: 'false',
        },
      ],
      onFilter: (value, record) =>
        record?.is_yearly_revision_date_expired?.toString().indexOf(value) === 0,
      sorter: (a, b) => a.is_yearly_revision_date_expired - b.is_yearly_revision_date_expired,
    },
  ]
  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total}`
  }

  const onSearch = (searchValue) => {
    setIsLoading(true)
    if (searchValue === '') {
      setFilteredDocuments(documents)
      setIsLoading(false)
    } else {
      setFilteredDocuments(
        documents.filter((document: any) =>
          document.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      )
      setIsLoading(false)
    }
  }

  function onChange(pagination, filters, sorter, extra) {
    if (extra.action === 'paginate') {
      window.scrollTo({
        top: 0,
      })
    }
  }

  return (
    <>
      <Search
        allowClear
        placeholder={`Search Title`}
        onSearch={onSearch}
        style={{ paddingBottom: 20 }}
      />
      <Table
        columns={columns}
        dataSource={filteredDocuments}
        rowKey="url"
        pagination={{
          showTotal: showTotal,
          total: pageCount,
          defaultPageSize: 20,
        }}
        loading={isLoading}
        onChange={onChange}
      />
    </>
  )
}
