import React, { useEffect, useState } from 'react'
import { getIssues, getItems, getProjects, getUsers, getMilestone, getLabels } from '../services'
import { Avatar, Button, Card, Cascader, Layout, Menu, Tag, Tooltip, Typography } from 'antd'
import { signIn } from '../../Common/FirebaseAuth'
import styles from './GitHubStatsPage.module.css'
import { LinkOutlined, GithubFilled } from '@ant-design/icons'

import { connect } from 'react-redux'
import {
  setGitHubUserInfo as setGitHubSettingsAction,
  setGitHubToken as setGitHubTokenAction,
} from '../../../domain/settings/slice'
import { projectPlanningRootURL } from '../../Common/constants'
import { RootState } from '../../../domain/rootReducer'
import { Project } from '../interfaces'
import { ActiveProjectError } from '../ActiveProjectError'
import { GitHubUnauthorized } from '../GitHubUnauthorized'
import { GitHubRoutes } from '../../Routes'
import { Loading } from '../../Common/Loading'
import { Link, useLocation } from 'react-router-dom'
import { SomethingWentWrongPage } from '../../Common/SomethingWentWrongPage'

const mapDispatch = {
  setGitHubUserInfo: setGitHubSettingsAction,
  setGitHubToken: setGitHubTokenAction,
} as const
const mapState = ({ settings }: RootState) => ({ settings } as const)

type Props = ReturnType<typeof mapState> & typeof mapDispatch

const GitHubStats = ({ settings, setGitHubUserInfo, setGitHubToken }: Props) => {
  const [issues, setIssues] = useState([])
  const [users, setUsers] = useState([])
  const [projects, setProject] = useState<Project[]>([])
  const [selectedProject, setSelectedProject] = useState<Project>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [columnIssues, setColumnIssues] = useState<any>([])
  const [milestones, setMilestones] = useState<any>([])
  const [labels, setLabels] = useState<any>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const tokenCookieName = 'cigridGitHubToken'
  const userLocalStorageName = 'cigridGitHubUserProfile'
  const cookieAge = 8 // hours
  const refreshTime = 600000 // 10 minutes
  const findCookiePattern = new RegExp(`(?<=${tokenCookieName}=)(.*?)(?=;)`, 'g')

  const { Content, Header, Sider } = Layout
  const { Text } = Typography

  const activeProjectBody = 'Active'

  const projectsDropdownItems = projects.map((project) => {
    return {
      label:
        project.body === activeProjectBody ? (
          <div>
            <Tag color={'green'}>{project.body}</Tag> {project.name}
          </div>
        ) : (
          project.name
        ),
      value: project.columns_url,
    }
  })

  useEffect(() => {
    const cookieToken = `${document.cookie};`.match(findCookiePattern)
    const localStorageUser = localStorage.getItem(userLocalStorageName)
    if (cookieToken?.length) {
      loadGitHubContent(cookieToken[0], JSON.parse(localStorageUser || ''))
      const interval = setInterval(() => {
        loadGitHubContent(cookieToken[0], JSON.parse(localStorageUser || ''))
      }, refreshTime)
      return () => clearInterval(interval)
    } else setIsLoading(false)
  }, [])

  const gitHubLogin = () => {
    setIsLoading(true)
    signIn().then((response: any) => {
      loadGitHubContent(response.credential.accessToken, response.additionalUserInfo.profile)
      document.cookie = `${tokenCookieName}=${response.credential.accessToken}; Max-Age=${
        cookieAge * 60 * 60
      };`
      localStorage.setItem(
        userLocalStorageName,
        JSON.stringify(response.additionalUserInfo.profile)
      )
    })
  }

  const menuItems = [
    {
      key: 'issues',
      label: <Link to={`${projectPlanningRootURL}/issues`}>Projects</Link>,
    },
    {
      key: 'users',
      label: <Link to={`${projectPlanningRootURL}/users`}>Users</Link>,
    },
    {
      key: 'area',
      label: <Link to={`${projectPlanningRootURL}/areas`}>Areas</Link>,
    },
    {
      key: 'milestone',
      label: <Link to={`${projectPlanningRootURL}/milestones`}>Milestones</Link>,
    },
    {
      key: 'pyramid',
      label: <Link to={`${projectPlanningRootURL}/pyramid`}>Pyramid</Link>,
    },
  ]

  const loadGitHubContent = (token, userInfo) => {
    setGitHubToken(token)
    setGitHubUserInfo(userInfo)
    getUsers(token).then((response) => {
      setUsers(response)
    })
    getIssues(token).then((response) => {
      setIssues(response)
    })
    getMilestone(token).then((response) => setMilestones(response))
    getLabels(token).then((response) => setLabels(response))
    getProjects(token)
      .then((response) => {
        setProject(response)
        const defaultProject =
          response.find((project) => project.body === activeProjectBody) || response[0]
        if (defaultProject) {
          setSelectedProject(defaultProject)
          populateProject(defaultProject.columns_url, token)
          setHasError(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setHasError(true)
      })
  }

  const populateProject = (projectColumnsURL, token = settings.gitHubToken || '') => {
    setIsLoading(true)
    getItems(token, projectColumnsURL)
      .then((columns) => {
        const localColumnIssues: any[] = []
        columns.map((column, i) =>
          getItems(token, column.cards_url).then((cards) => {
            localColumnIssues.push({
              column: column.name,
              columnIndex: i,
              issues: cards.map((card) => card.content_url),
            })
            if (i === columns.length - 1) {
              setColumnIssues(localColumnIssues)
            }
          })
        )
      })
      .then(() => setIsLoading(false))
  }

  return (
    <Layout>
      <Sider width={180} style={{ backgroundColor: 'transparent' }}>
        <Card className={styles.gitHubLogin}>
          <div>
            {!settings?.gitHubToken && (
              <Button onClick={gitHubLogin} icon={<GithubFilled />} size="middle">
                GitHub Login
              </Button>
            )}
            {settings?.gitHubToken && (
              <div className={styles.userCard}>
                <Avatar
                  src={settings?.gitHubUser?.avatar_url as string}
                  className={styles.userAvatar}
                ></Avatar>
                <Text>{`Hi ${(settings?.gitHubUser?.login as string).slice(0, 9)}`}</Text>
              </div>
            )}
          </div>
        </Card>
        <Menu
          mode="inline"
          className={styles.sideMenu}
          items={menuItems}
          selectedKeys={[useLocation().pathname.split('/')[2]]}
        />
      </Sider>
      <Layout>
        <Card style={{ borderRadius: 0, borderLeft: 0, minHeight: '80px' }}>
          {selectedProject ? (
            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
              Selected quarter:
              <Cascader
                allowClear={false}
                style={{ width: 250 }}
                options={projectsDropdownItems}
                defaultValue={[
                  projectsDropdownItems.find((item) => item.value === selectedProject?.columns_url)
                    ?.value || projectsDropdownItems[0]?.value,
                ]}
                onChange={(e) => {
                  const selectedProjectColumnURL = e[0]
                  setSelectedProject(
                    projects.find((project) => project.columns_url === selectedProjectColumnURL)
                  )
                  populateProject(selectedProjectColumnURL)
                }}
              />
              <Tooltip title="Open quarter on GitHub">
                <a href={selectedProject?.html_url} target={'_blank'} rel="noreferrer">
                  <LinkOutlined />
                </a>
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </Card>
        <Content style={{ minHeight: '83vh', padding: '10px 24px 24px' }}>
          {isLoading ? (
            <Loading />
          ) : settings.gitHubToken ? (
            hasError ? (
              <SomethingWentWrongPage />
            ) : selectedProject ? (
              <div>
                <GitHubRoutes
                  issues={issues}
                  columnIssues={columnIssues}
                  project={selectedProject}
                  users={users}
                  milestones={milestones}
                  labels={labels}
                />
              </div>
            ) : (
              <ActiveProjectError />
            )
          ) : (
            <GitHubUnauthorized />
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export const GitHubStatsPage = connect(mapState, mapDispatch)(GitHubStats)
