import React from 'react'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './domain/store'

const container = document.getElementById('root')
const root = createRoot(container)

// Check if the mock API is enabled (via REACT_APP_MOCK_API environment variable)
// If enabled, require the mock service worker module to intercept network requests
if (process.env.REACT_APP_MOCK_API === 'true') {
  require('./browser')
}

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
