import React from 'react'
import { Card, Tabs } from 'antd'
import { getTurnaroundTimes } from '../../services'
import { TrendingGraph } from '../TrendingGraph'

export const TurnaroundTimeTrendingPage = () => {
  const grouping = ['tag', 'priority']
  const { TabPane } = Tabs
  return (
    <Card>
      <Tabs defaultActiveKey={grouping[0]} type="card">
        {grouping.map((group) => (
          <TabPane tab={`Grouped by ${group}`} key={group}>
            <TrendingGraph
              populateGraphMethod={getTurnaroundTimes}
              additionalParameters={[group]}
            />
          </TabPane>
        ))}
      </Tabs>
    </Card>
  )
}
