import React, { useEffect, useState } from 'react'
import { getStatistics } from './services'
import { Column } from '@ant-design/charts'
import { Card } from 'antd'
import { cleanTitle } from '../Common/services/helpers/helpers'
import { Loading } from '../Common/Loading'

export const StatisticsPage = ({ darkMode }) => {
  const [statistics, setStatistics] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getStatistics()
      .then((response) => {
        setStatistics(response[0])
        setIsLoading(false)
      })
      .catch(() => setIsLoading(true))
  }, [])

  const data = Object.keys(statistics).map((item) => ({
    label: cleanTitle(item),
    size: statistics[item],
  }))

  const config = {
    data,
    xField: 'label',
    yField: 'size',
    theme: darkMode ? 'dark' : 'light',
    label: {
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
  }
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore*/}
          <Column {...config} />
        </Card>
      )}
    </>
  )
}
