import React from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

interface VisibilityToggleBulkActionProps {
  handleBulkAction: any
  hasSelected: boolean
  ids: string[]
  updateHandler: any
  isSelectedCaseInView: boolean
}

export const VisibilityToggleBulkAction: React.FC<VisibilityToggleBulkActionProps> = ({
  handleBulkAction,
  hasSelected,
  ids,
  updateHandler,
  isSelectedCaseInView,
}) => {
  const getPopconfirmTitle = (actionType: string) => {
    if (!isSelectedCaseInView) {
      return `Some cases are filtered out due to active filters or sorting. Are you sure you want to ${actionType}?`
    }
    return `Are you sure you want to ${actionType}?`
  }

  const renderPopconfirm = (actionType, icon, tooltipTitle) => (
    <Popconfirm
      title={getPopconfirmTitle(actionType)}
      onConfirm={() => handleBulkAction('visibility', actionType !== 'hide', ids, updateHandler)}
      okText="Yes"
      cancelText="No"
      disabled={!hasSelected}
    >
      <Tooltip title={tooltipTitle} placement="bottom">
        <Button disabled={!hasSelected} icon={icon}></Button>
      </Tooltip>
    </Popconfirm>
  )

  return (
    <>
      {renderPopconfirm('hide', <EyeInvisibleOutlined />, 'Mark as hidden')}
      {renderPopconfirm('unhide', <EyeOutlined />, 'Mark as unhidden')}
    </>
  )
}
