import React, { useState, useEffect } from 'react'

const SaveScrollPosition = ({ componentName, workflow }) => {
  const [scrollPosition, setScrollPosition] = useState(() => {
    const savedScrollPosition = localStorage.getItem(componentName)
    return savedScrollPosition ? parseInt(savedScrollPosition) : window.scrollY
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [workflow])

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem(componentName)
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition))
    }
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [componentName])

  useEffect(() => {
    localStorage.setItem(componentName, scrollPosition.toString())
    if (componentName === 'EditableAnalysesTable') {
      localStorage.setItem('EditableOrdersTable', '0')
    } else if (componentName === 'EditableOrdersTable') {
      localStorage.setItem('EditableAnalysesTable', '0')
    }
  }, [scrollPosition, componentName])

  return <div>{/* Scrollable content */}</div>
}

export default SaveScrollPosition
