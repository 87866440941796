import React from 'react'
import { OwnerTable } from './AtlasTables/OwnerTable'

export const OwnerPage = () => {
  return (
    <>
      <OwnerTable />
    </>
  )
}
