import React from 'react'
import { DynamicPlatesTable } from '../PlatesTable/DynamicPlatesTable'

export const PlateListPage = () => {
  return (
    <div>
      <DynamicPlatesTable />
    </div>
  )
}
