import { axiosCall } from '../Common/services/helpers/helpers'
import axios from 'axios'
const { REACT_APP_BACKEND_ATLAS_URL } = process.env

export const getDocuments = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/documents`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getOwners = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/owners`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getOwnerDocuments = async (ownerId: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/owners/${ownerId}/documents`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getStatistics = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/statistics`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getAtlasVersion = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/version`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getDocumentPath = async (title: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ATLAS_URL}/title/${encodeURIComponent(title)}/path`
  return axiosCall(axios.get.bind(null, endPoint))
}
