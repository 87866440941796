import React, { useEffect, useState } from 'react'
import { Card, Table, Input, Tag } from 'antd'
import { useLocation } from 'react-router-dom'
import { getOwnerDocuments } from '../services'
import { sortDate } from '../../Common/services/helpers/helpers'
import styles from './OwnerTable.module.css'
import { AtlasTagColors } from '../../Common/styleHelpers'

const { Search } = Input

export const OwnerTable = () => {
  const [ownerDocuments, setOwnerDocuments] = useState([])
  const [filteredOwnerDocuments, setFilteredOwnerDocuments] = useState(ownerDocuments)
  const [userName, setUserName] = useState('')
  const [pageCount, setPageCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { pathname } = useLocation()
  const ownerId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)

  useEffect(() => {
    getOwnerDocuments(ownerId).then((response) => {
      setOwnerDocuments(response), setUserName(response[0].user_name)
      setFilteredOwnerDocuments(response)
      setPageCount(response.length)
      setIsLoading(false)
    })
  }, [])

  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: any, data: any) => (
        <a href={data.url} target="_blank" rel="noreferrer">
          {title}
        </a>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Last Revision',
      dataIndex: 'revision_date',
      key: 'revision_date',
      sorter: (a, b) => sortDate(a.revision_date, b.revision_date),
    },
    {
      title: 'Next Revision',
      dataIndex: 'yearly_revision_date',
      key: 'yearly_revision_date',
      sorter: (a, b) => sortDate(a.yearly_revision_date, b.yearly_revision_date),
    },
    {
      title: 'Status',
      dataIndex: 'is_yearly_revision_date_expired',
      key: 'is_yearly_revision_date_expired',
      render: (is_yearly_revision_date_expired: boolean) =>
        is_yearly_revision_date_expired ? (
          <Tag color={AtlasTagColors.expired}>Needs Revision</Tag>
        ) : (
          <Tag color={AtlasTagColors.upToDate}>Up To Date</Tag>
        ),
      filters: [
        {
          text: 'Needs Revision',
          value: 'true',
        },
        {
          text: 'Up To Date',
          value: 'false',
        },
      ],
      onFilter: (value, record) =>
        record?.is_yearly_revision_date_expired?.toString().indexOf(value) === 0,
      sorter: (a, b) => a.is_yearly_revision_date_expired - b.is_yearly_revision_date_expired,
    },
  ]
  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total}`
  }

  const onSearch = (searchValue) => {
    setIsLoading(true)
    if (searchValue === '') {
      setFilteredOwnerDocuments(ownerDocuments)
      setIsLoading(false)
    } else {
      setFilteredOwnerDocuments(
        ownerDocuments.filter((documents: any) =>
          documents.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      )
      setIsLoading(false)
    }
  }

  function onChange(pagination, filters, sorter, extra) {
    if (extra.action === 'paginate') {
      window.scrollTo({
        top: 0,
      })
    }
  }

  return (
    <>
      <Card>
        <h1 className={styles.title}>{userName}</h1>
        {isLoading ? null : <p>Number of documents: {pageCount}</p>}
        <Search
          allowClear
          placeholder={`Search Title`}
          onSearch={onSearch}
          style={{ paddingBottom: 20 }}
        />
        <Table
          className={styles.table}
          columns={columns}
          dataSource={filteredOwnerDocuments}
          rowKey="url"
          pagination={{
            showTotal: showTotal,
            total: pageCount,
            defaultPageSize: 20,
          }}
          loading={isLoading}
          onChange={onChange}
        />
      </Card>
    </>
  )
}
