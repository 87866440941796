import React from 'react'
import { Button } from 'antd'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleOutlined } from '@ant-design/icons'

import { exchangeAuthorizationCode } from '../modules/Trailblazer/services'
import styles from '../App.module.css'

export const LoginButton = ({ onLoginSuccess, onLoginFailure }) => {
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      const token = await exchangeAuthorizationCode(codeResponse.code)
      onLoginSuccess(token)
    },
    onError: () => onLoginFailure(),
  })

  return (
    <div className={styles.loginButton}>
      <Button onClick={() => googleLogin()}>
        <GoogleOutlined />
        Sign in with Google
      </Button>
    </div>
  )
}
