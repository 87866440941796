import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import { DatabaseOutlined, FallOutlined, ExperimentOutlined } from '@ant-design/icons'
import { AnalysesRoutes, OrdersRoutes } from '../Routes'
import mainStyles from '../../App.module.css'
import { analysesRootURL, ordersRootURL } from '../Common/constants'

export const TrailblazerHomePage = ({ isLoggedIn }) => {
  const { Sider, Content } = Layout
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(true)
  const location = useLocation()

  // Define the menu items
  const menuItems = [
    {
      key: 'all-orders',
      icon: <DatabaseOutlined />,
      label: <Link to={`${ordersRootURL}/all-orders`}>Orders</Link>,
    },
    {
      key: 'all-analyses',
      icon: <ExperimentOutlined />,
      label: <Link to={`${analysesRootURL}/all-analyses`}>Analyses</Link>,
    },
    {
      key: 'stats',
      icon: <FallOutlined />,
      label: <Link to={`${analysesRootURL}/stats`}>Stats</Link>,
    },
  ]

  return (
    <Layout>
      <Sider
        style={{ paddingBottom: '0' }}
        width={130}
        collapsible
        collapsed={isMenuCollapsed}
        onCollapse={() => setIsMenuCollapsed(!isMenuCollapsed)}
      >
        <Menu
          mode="inline"
          style={{
            height: '100%',
            position: 'fixed',
            width: isMenuCollapsed ? '80px' : '135px',
          }}
          selectedKeys={location.pathname.split('/').slice(-1)}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Content className={mainStyles.content}>
          <Switch>
            <Route
              path={`${ordersRootURL}`}
              render={() => <OrdersRoutes isLoggedIn={isLoggedIn} />}
            />
            <Route
              path={`${analysesRootURL}`}
              render={() => <AnalysesRoutes isLoggedIn={isLoggedIn} />}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}
