import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import { AppMenu } from './AppMenu'
import { DropDownMenu } from './DropdownMenu'
import styles from '../App.module.css'
import Logo from '../assets/logo.png'
import { LoginButton } from './LoginButton'

const { Header } = Layout

export const AppHeader = ({
  settings,
  darkMode,
  logout,
  themeSwitch,
  onLoginFailure,
  onLoginSuccess,
}) => {
  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        backgroundColor: darkMode ? '#141414' : '',
      }}
    >
      <Link to="/">
        <img className={styles.logo} src={Logo} alt={'Small CG logo'} />
      </Link>
      <AppMenu settings={settings} darkMode={darkMode} />
      <div>
        {settings?.googleToken == null && (
          <LoginButton onLoginSuccess={onLoginSuccess} onLoginFailure={onLoginFailure} />
        )}
        {settings?.googleToken !== null && (
          <DropDownMenu
            logout={logout}
            settings={settings}
            darkMode={darkMode}
            themeSwitch={themeSwitch}
          />
        )}
      </div>
    </Header>
  )
}
