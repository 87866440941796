import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { analysesRootURL, projectPlanningRootURL } from '../modules/Common/constants'

export const appMenuItems = (settings) => {
  const token = settings?.googleToken
  const trailblazerKey = useLocation().pathname.split('/')[1]

  return [
    {
      key: trailblazerKey === 'analyses' ? 'analyses' : 'orders',
      label: token ? <Link to={`${analysesRootURL}`}>Trailblazer</Link> : 'Trailblazer',
      disabled: token === null,
    },
    {
      key: 'atlas',
      label: <Link to="/atlas">Atlas Stats</Link>,
    },
    {
      key: 'lab-preps',
      label: token ? <Link to="/lab-preps">Lab Preps</Link> : 'Lab Preps',
      disabled: token === null,
    },
    {
      key: 'project-planning',
      label: <Link to={`${projectPlanningRootURL}`}>Project Planning</Link>,
    },
    {
      key: 'genotype',
      label: token ? <Link to="/genotype">Genotype</Link> : 'Genotype',
      disabled: token === null,
    },
  ]
}
