import React from 'react'
import { Button, Modal, Popconfirm, Tooltip, notification } from 'antd'
import { isWorkflowDeliverable } from './utils'
import { CopyOutlined } from '@ant-design/icons'

interface DeliveryMessageBulkActionProps {
  sameTicketIds: boolean
  notUploaded: boolean
  notUploadedCases: any[]
  handleBulkAction: any
  ids: string[]
  updateHandler: any
  hasSelected: boolean
  workflow: string[]
  caseIds: string[]
  isSelectedCaseInView: boolean
}

export const DeliveryMessageBulkAction: React.FC<DeliveryMessageBulkActionProps> = ({
  sameTicketIds,
  notUploaded,
  notUploadedCases,
  handleBulkAction,
  ids,
  updateHandler,
  hasSelected,
  workflow,
  caseIds,
  isSelectedCaseInView,
}) => {
  const handleConfirmMarkDelivered = (type: 'delivery' | 'copy') => {
    Modal.confirm({
      title: type === 'delivery' ? 'Mark as Delivered' : 'Copy Delivery Message',
      content: (
        <div>
          <p>
            The following analyses are not uploaded: <br />
            {notUploadedCases.map((caseObj) => (
              <React.Fragment key={caseObj.caseId}>
                <strong>{caseObj.caseId}</strong>
                <br />
              </React.Fragment>
            ))}
          </p>
          {type === 'delivery' ? (
            <p>Do you want to proceed with the delivery?</p>
          ) : (
            <p>Do you want to proceed with copying the delivery message?</p>
          )}
        </div>
      ),
      onOk() {
        handleBulkAction(
          type === 'delivery' ? 'delivered' : 'deliveryMessage',
          true,
          ids,
          updateHandler,
          caseIds
        )
        if (type === 'copy') {
          handleConfirmMarkDelivered('delivery')
        }
      },
    })
  }

  const handleButtonClick = (type: 'delivery' | 'copy') => {
    if (notUploaded) {
      handleConfirmMarkDelivered(type)
    } else {
      handleBulkAction(
        type === 'delivery' ? 'delivered' : 'deliveryMessage',
        true,
        ids,
        updateHandler,
        caseIds
      )
    }
  }

  return (
    <>
      {sameTicketIds ? (
        notUploaded ? (
          <Tooltip title="Copy delivery message" placement="bottom">
            <Button
              disabled={!hasSelected || isWorkflowDeliverable(workflow)}
              icon={<CopyOutlined />}
              onClick={() => handleButtonClick('copy')}
            />
          </Tooltip>
        ) : (
          <Popconfirm
            title={
              !isSelectedCaseInView
                ? 'Some cases are filtered out due to active filters or sorting. Do you want to mark the analyses as delivered?'
                : 'Do you want to mark the analyses as delivered?'
            }
            onConfirm={() => handleButtonClick('delivery')}
            disabled={!hasSelected || isWorkflowDeliverable(workflow)}
          >
            <Tooltip title="Copy delivery message" placement="bottom">
              <Button
                disabled={!hasSelected || isWorkflowDeliverable(workflow)}
                icon={<CopyOutlined />}
                onClick={() => handleButtonClick('copy')}
              />
            </Tooltip>
          </Popconfirm>
        )
      ) : (
        <Tooltip title="Copy delivery message" placement="bottom">
          <Button
            disabled={!hasSelected}
            icon={<CopyOutlined />}
            onClick={() =>
              notification.error({
                message: "Message Can't be copied",
                description: 'To deliver multiple cases the ticket should be the same.',
              })
            }
          />
        </Tooltip>
      )}
    </>
  )
}
