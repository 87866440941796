import React, { useEffect, useState } from 'react'
import { Card, Tag, Flex, Typography, Anchor } from 'antd'
import { getWorkflowTagColor } from '../../../Common/styleHelpers'
import { supportSystemTicketLink } from '../../constants'
import { AnalysisStatus } from '../../EditableTable/Component/AnalysisStatus'
import { OrderDeliveryMessageBtn } from './OrderDeliveryMessageBtn'
import { editOrder } from '../../services'
import { OrderOpeningAction } from './OrderOpeningAction'
import { createNotification } from '../../../Common/services/helpers/helpers'

export const OrderSummary = ({ order, handleDelivery }) => {
  const { Text } = Typography
  const { Link } = Anchor
  const [orderStatus, setOrderStatus] = useState(order?.is_open)

  const handleOpeningAction = (id, open: boolean) => {
    return editOrder(undefined, id, open).then((response) => {
      setOrderStatus(response.is_open)
      createNotification('success', 'Order status updated successfully')
      return response
    })
  }

  const TicketLink = (ticketId) => (
    <Link href={supportSystemTicketLink(ticketId)} title={`Ticket #${ticketId}`} />
  )

  useEffect(() => {
    document.title = `Order: ${order?.id}`
  }, [order])

  return (
    <Card>
      <Flex justify="space-between" align="center">
        <Flex vertical gap="small" align="start">
          <Text>
            {`Order: ${order?.id}`}{' '}
            <OrderDeliveryMessageBtn order={order} handleDelivery={handleDelivery} />
            <OrderOpeningAction order={order} handleOpening={handleOpeningAction} />
          </Text>
          {TicketLink(order?.ticket_id)}
          <Text>{`Ordered at ${order?.order_date}`}</Text>
          <Text>{order?.customer_id}</Text>
          <Flex>
            <Tag color={getWorkflowTagColor(order?.workflow)}>{order?.workflow?.toUpperCase()}</Tag>
            {orderStatus ? <Tag color="yellow">OPEN</Tag> : <Tag color="green">CLOSED</Tag>}
          </Flex>
        </Flex>
        <Flex vertical>
          <Flex>
            <AnalysisStatus status={order?.summary} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
