import React from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { MessageOutlined } from '@ant-design/icons'

interface CommentBulkActionProps {
  hasSelected: boolean
  toggleCommentInputVisibility: any
  isSelectedCaseInView: boolean
}

export const CommentBulkAction: React.FC<CommentBulkActionProps> = ({
  hasSelected,
  toggleCommentInputVisibility,
  isSelectedCaseInView,
}) => {
  return (
    <>
      {!isSelectedCaseInView ? (
        <Popconfirm
          title="Some cases are filtered out due to active filters or sorting. Are you sure you want to add a comment?"
          onConfirm={toggleCommentInputVisibility}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Comment" placement="bottom">
            <Button disabled={!hasSelected} icon={<MessageOutlined />}></Button>
          </Tooltip>
        </Popconfirm>
      ) : (
        <Tooltip title="Comment" placement="bottom">
          <Button
            disabled={!hasSelected}
            icon={<MessageOutlined />}
            onClick={toggleCommentInputVisibility}
          ></Button>
        </Tooltip>
      )}
    </>
  )
}
