import * as React from 'react'
import styles from './LinkList.module.css'
import atlasLogo from '../../../../assets/apps/atlas.png'
import atlasDevLogo from '../../../../assets/apps/atlas-dev.png'
import cgLogo from '../../../../assets/apps/cg.png'
import cgLogoNew from '../../../../assets/apps/cg-new.png'
import supportSystemLogo from '../../../../assets/apps/support-system.png'
import limsLogo from '../../../../assets/apps/lims.png'
import genotypeLogo from '../../../../assets/apps/genotype.png'
import scoutLogo from '../../../../assets/apps/scout.png'
import statinaLogo from '../../../../assets/apps/statina.png'
import gitHubLogo from '../../../../assets/apps/github.png'
import adminLogo from '../../../../assets/apps/admin.png'
import paxLogo from '../../../../assets/apps/pax.png'
import gangliaLogo from '../../../../assets/apps/ganglia.png'
import patientMatcher from '../../../../assets/apps/patient-matcher.png'
import towerLogo from '../../../../assets/apps/tower.png'
import arnoldLogo from '../../../../assets/apps/arnold.png'
import freshdesk from '../../../../assets/apps/freshdesk.png'
import { Link } from '../Link/Link'
import { Card, Tabs } from 'antd'
import { useState } from 'react'

export const LinkList = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>('production')

  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const contentList: Record<string, React.ReactNode> = {
    production: (
      <div className={styles.linksContainer}>
        <div className={styles.appHolder}>
          <Link img={atlasLogo} title={'Atlas'} url={'https://atlas.scilifelab.se/'} />
          <Link
            img={atlasDevLogo}
            title={'Atlas Live Editor'}
            url={'https://github.dev/Clinical-Genomics/atlas'}
          />
          <Link img={gitHubLogo} title={'CG GitHub'} url={'https://github.com/Clinical-Genomics'} />
          <Link
            img={limsLogo}
            title={'Clarity LIMS'}
            url={'https://cg-lims.sys.scilifelab.se/clarity/'}
          />
          <Link img={gangliaLogo} title={'Ganglia'} url={'https://hasta.scilifelab.se/ganglia'} />
          <Link img={cgLogo} title={'Order Portal (old)'} url={'https://cgweb-1c096.web.app/'} />
          <Link
            img={cgLogoNew}
            title={'Order Portal (new)'}
            url={'https://clinical.scilifelab.se'}
          />
          <Link
            img={patientMatcher}
            title={'Patient Matcher'}
            url={'https://patientmatcher.scilifelab.se/'}
          />
          <Link img={paxLogo} title={'Pax'} url={'https://pax.scilifelab.se'} />
          <Link img={scoutLogo} title={'Scout'} url={'https://scout.scilifelab.se/'} />
          <Link img={statinaLogo} title={'Statina'} url={'https://statina.clinicalgenomics.se/'} />
          <Link
            img={adminLogo}
            title={'StatusDB'}
            url={'https://cg-statusdb.scilifelab.se/admin/'}
          />
          <Link
            img={supportSystemLogo}
            title={'Support'}
            url={'https://clinical-scilifelab.supportsystem.com/scp/login.php'}
          />
          <Link
            img={freshdesk}
            title={'Freshdesk'}
            url={'https://scilifelab.freshdesk.com/a/dashboard/default'}
          />
          <Link img={towerLogo} title={'Tower'} url={'https://cg-tower-prod.scilifelab.se/'} />
          <Link
            img={arnoldLogo}
            title={'Arnold Swagger'}
            url={'https://arnold.scilifelab.se/docs'}
          />
          <Link
            img={genotypeLogo}
            title={'Genotype Swagger'}
            url={'https://genotype-api.sys.scilifelab.se/api/v2/docs'}
          />
          <Link
            img={statinaLogo}
            title={'Statina Swagger'}
            url={'https://statina.scilifelab.se/docs'}
          />
          <Link
            img={towerLogo}
            title={'Tower API'}
            url={'https://cg-tower-prod.scilifelab.se/openapi/index.html'}
          />
        </div>
      </div>
    ),
    staging: (
      <div className={styles.linksContainer}>
        <div className={styles.appHolder}>
          <Link img={cgLogo} title={'Order Portal (old)'} url={'https://cgweb-stage.web.app/'} />
          <Link
            img={cgLogoNew}
            title={'Order Portal (new)'}
            url={'https://clinical-stage.scilifelab.se'}
          />
          <Link
            img={patientMatcher}
            title={'Patient Matcher'}
            url={'https://patientmatcher-stage.scilifelab.se/'}
          />

          <Link img={scoutLogo} title={'Scout '} url={'https://scout-stage.scilifelab.se/'} />
          <Link
            img={statinaLogo}
            title={'Statina '}
            url={'https://statina-stage.clinicalgenomics.se/'}
          />
          <Link img={towerLogo} title={'Tower'} url={'https://cg-tower-stage.scilifelab.se/'} />
          <Link
            img={arnoldLogo}
            title={'Arnold Swagger'}
            url={'https://arnold-stage.scilifelab.se/docs'}
          />
          <Link
            img={genotypeLogo}
            title={'Genotype Swagger'}
            url={'https://genotype-api-stage.sys.scilifelab.se/api/v2/docs'}
          />
          <Link
            img={statinaLogo}
            title={'Statina Swagger'}
            url={'https://statina-stage.scilifelab.se/docs'}
          />
          <Link
            img={towerLogo}
            title={'Tower API'}
            url={'https://cg-tower-stage.scilifelab.se/openapi/index.html'}
          />
          <Link
            img={limsLogo}
            title={'Clarity LIMS'}
            url={'https://cg-lims-stage.sys.scilifelab.se/clarity/'}
          />
        </div>
      </div>
    ),
  }

  const items = [
    { label: 'Production', key: 'production', children: contentList.production },
    { label: 'Staging', key: 'staging', children: contentList.staging },
  ]
  return (
    <Card>
      <Tabs
        items={items}
        type="card"
        activeKey={activeTabKey}
        onChange={(key) => {
          onTabChange(key)
        }}
      >
        <div className={styles.mainContainer}>{contentList[activeTabKey]}</div>
      </Tabs>
    </Card>
  )
}
