import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, Card, Flex, Input, InputRef, Tooltip } from 'antd'
import { performBulkAction, BulkActionType } from './utils'
import SearchBar from '../Search/SearchBar'
import { VisibilityToggleBulkAction } from './VisibilityToggleBulkAction'
import { DeliveryMessageBulkAction } from './DeliveryMessageBulkAction'
import { StatusBulkActions } from './StatusBulkActions'
import { DeliveryStatusBulkAction } from './DeliveryStatusBulkAction'
import { CommentBulkAction } from './CommentBulkAction'
import { CopyCaseIds } from './CopyCaseIds'
import { IncludeHiddenAnalyses } from '../Component/IncludeHiddenAnalyses'

interface BulkActionsProps {
  hasSelected: boolean
  ids: string[]
  updateHandler: any
  searchData: any
  caseIds: string[]
  resetSelectedRows: any
  orderId: string | null
  sameTicketIds: boolean
  notUploaded: boolean
  notUploadedCases: string[]
  workflow: string[]
  includeHidden: boolean
  includeHiddenToggle: any
  isSelectedCaseInView: boolean
}

export const BulkActions: React.FC<BulkActionsProps> = ({
  hasSelected,
  ids,
  updateHandler,
  searchData,
  caseIds,
  resetSelectedRows,
  orderId,
  sameTicketIds,
  notUploaded,
  notUploadedCases,
  workflow,
  includeHidden,
  includeHiddenToggle,
  isSelectedCaseInView,
}) => {
  const [commentInputVisibility, setCommentInputVisibility] = useState<boolean>(false)
  const inputRef = useRef<InputRef>(null)
  const handleBulkAction = (
    type: BulkActionType,
    value: any,
    ids: string[],
    updateData: any,
    caseIds = null
  ) => {
    const analyses = performBulkAction(type, value, ids, caseIds)
    updateData(analyses)
    setCommentInputVisibility(false)
  }

  const toggleCommentInputVisibility = () => {
    setCommentInputVisibility(!commentInputVisibility)
  }

  useEffect(() => {
    if (commentInputVisibility && inputRef.current) {
      inputRef.current.focus()
    }
  }, [commentInputVisibility])

  useEffect(() => {
    if (!hasSelected) {
      setCommentInputVisibility(false)
    }
  }, [hasSelected])

  const resetSelected = () => {
    resetSelectedRows()
    setCommentInputVisibility(false)
  }

  return (
    <Card style={{ position: 'sticky', top: orderId ? 65 : 103, zIndex: 6 }}>
      <Flex gap="small" vertical>
        <Flex gap="small" justify="space-between">
          <Flex gap="small">
            <Flex align="center">
              <Tooltip title="Uncheck selection" placement="bottom">
                <Button disabled={!hasSelected} onClick={resetSelected} style={{ width: 60 }}>
                  <Badge
                    count={ids.length}
                    color={hasSelected ? 'blue' : '#d9d9d9'}
                    showZero={true}
                  />
                </Button>
              </Tooltip>
            </Flex>
            <CopyCaseIds
              caseIds={caseIds}
              hasSelected={hasSelected}
              isSelectedCaseInView={isSelectedCaseInView}
            />
            <VisibilityToggleBulkAction
              {...{
                handleBulkAction,
                hasSelected,
                ids,
                updateHandler,
                isSelectedCaseInView,
              }}
            />
            <DeliveryMessageBulkAction
              {...{
                sameTicketIds,
                notUploaded,
                notUploadedCases,
                handleBulkAction,
                ids,
                updateHandler,
                hasSelected,
                workflow,
                caseIds,
                isSelectedCaseInView,
              }}
            />
            <StatusBulkActions
              {...{ handleBulkAction, ids, updateHandler, hasSelected, isSelectedCaseInView }}
            />
            <DeliveryStatusBulkAction
              {...{
                handleBulkAction,
                ids,
                updateHandler,
                hasSelected,
                notUploaded,
                notUploadedCases,
                isSelectedCaseInView,
              }}
            />
            <CommentBulkAction
              {...{
                hasSelected,
                toggleCommentInputVisibility,
                isSelectedCaseInView,
              }}
            />
            {commentInputVisibility && (
              <div style={{ width: 400 }}>
                <Input
                  ref={inputRef}
                  disabled={!hasSelected}
                  placeholder="Type comment"
                  onPressEnter={(event) =>
                    handleBulkAction(
                      'comment',
                      (event.target as HTMLInputElement).value,
                      ids,
                      updateHandler
                    )
                  }
                />
              </div>
            )}
          </Flex>
          <SearchBar searchData={searchData} />
          <IncludeHiddenAnalyses
            {...{
              includeHidden,
              includeHiddenToggle,
            }}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
