import React, { useState, useEffect } from 'react'
import { UpOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export const BackToTop = () => {
  const [backToTopButton, setBackToTopButton] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 150) {
        setBackToTopButton(true)
      } else {
        setBackToTopButton(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {backToTopButton && (
        <div style={{ position: 'fixed', bottom: '50px', right: '30px', zIndex: 100 }}>
          <Button onClick={scrollToTop} type="primary" shape="circle" icon={<UpOutlined />} />
        </div>
      )}
    </>
  )
}
