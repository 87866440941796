import React from 'react'
import { Tag, Table } from 'antd'
import { FailedJob } from '../interfaces'

interface FailedJobsProps {
  failedJobs: FailedJob[] | undefined
  isLoading: boolean
}

export const FailedJobs = (props: FailedJobsProps) => {
  const { failedJobs, isLoading } = props

  const columns = [
    {
      title: 'Failed jobs',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number of failures',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
      render: function jobs(count: number) {
        return <Tag color="red">{count}</Tag>
      },
    },
  ]

  return (
    <Table
      dataSource={failedJobs}
      columns={columns}
      loading={isLoading}
      pagination={false}
      rowKey={(job) => job.name}
      tableLayout={'fixed'}
    />
  )
}
