import React, { useEffect, useState } from 'react'
import { EditableAnalysesTable } from '../EditableTable/EditableAnalysesTable'
import { setAnalyses as setAnalysesAction } from '../../../domain/settings/slice'
import { RootState } from '../../../domain/rootReducer'
import { connect } from 'react-redux'
import { Button, Card, Tabs } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { analysesRootURL } from '../../Common/constants'
import { getWorkflowFromURL } from '../utils'

const mapDispatch = {
  setAnalyses: setAnalysesAction,
} as const
const mapState = ({ settings }: RootState) => ({ settings } as const)

type Props = ReturnType<typeof mapState> & typeof mapDispatch

export const AnalysesComponent = ({ settings, setAnalyses }: Props) => {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [workflow, setWorkflow] = useState<string>(getWorkflowFromURL(location.pathname))

  useEffect(() => {
    const handleBackButton = () => {
      setWorkflow(getWorkflowFromURL(location.pathname))
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  const refreshAnalyses = () => {
    setRefresh((prev) => !prev)
    window.localStorage.setItem('scrollPosition', '0')
  }
  const refreshAnalysesButton = {
    right: (
      <Button onClick={refreshAnalyses} type="link" size="large" icon={<SyncOutlined />}>
        Refresh analyses
      </Button>
    ),
  }

  const handleTabChange = (key: string) => {
    setWorkflow(key)
  }

  const tabItems = [
    {
      label: (
        <Link to={`${analysesRootURL}/all-analyses`} style={{ color: 'inherit' }}>
          ALL
        </Link>
      ),
      key: '',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/balsamic`} style={{ color: 'inherit' }}>
          BALSAMIC
        </Link>
      ),
      key: 'balsamic',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/mip-dna`} style={{ color: 'inherit' }}>
          MIP DNA
        </Link>
      ),
      key: 'mip-dna',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/mip-rna`} style={{ color: 'inherit' }}>
          MIP RNA
        </Link>
      ),
      key: 'mip-rna',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/fluffy`} style={{ color: 'inherit' }}>
          FLUFFY
        </Link>
      ),
      key: 'fluffy',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/microsalt`} style={{ color: 'inherit' }}>
          MICROSALT
        </Link>
      ),
      key: 'microsalt',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/mutant`} style={{ color: 'inherit' }}>
          MUTANT
        </Link>
      ),
      key: 'mutant',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/demultiplex`} style={{ color: 'inherit' }}>
          DEMULTIPLEX
        </Link>
      ),
      key: 'demultiplex',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/rsync`} style={{ color: 'inherit' }}>
          RSYNC
        </Link>
      ),
      key: 'rsync',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/rnafusion`} style={{ color: 'inherit' }}>
          RNAFUSION
        </Link>
      ),
      key: 'rnafusion',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/taxprofiler`} style={{ color: 'inherit' }}>
          TAXPROFILER
        </Link>
      ),
      key: 'taxprofiler',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/raw-data`} style={{ color: 'inherit' }}>
          RAW DATA
        </Link>
      ),
      key: 'raw-data',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/tomte`} style={{ color: 'inherit' }}>
          TOMTE
        </Link>
      ),
      key: 'tomte',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
    {
      label: (
        <Link to={`${analysesRootURL}/raredisease`} style={{ color: 'inherit' }}>
          RAREDISEASE
        </Link>
      ),
      key: 'raredisease',
      children: <EditableAnalysesTable workflow={workflow} refresh={refresh} />,
    },
  ]

  const tabStyle = {
    backgroundColor: settings.themeMode ? '#141414' : 'white',
  }

  return (
    <>
      <Card>
        <Tabs
          items={tabItems}
          tabBarExtraContent={refreshAnalysesButton}
          onTabClick={handleTabChange}
          activeKey={workflow}
          type="card"
          tabBarStyle={{ position: 'sticky', top: 64, zIndex: 10, ...tabStyle }}
        />
      </Card>
    </>
  )
}

export const AnalysesPage = React.memo(connect(mapState, mapDispatch)(AnalysesComponent))
