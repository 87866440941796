import { axiosCall } from '../Common/services/helpers/helpers'
import { default as axios } from 'axios'

export const { REACT_APP_BACKEND_ARNOLD_URL } = process.env

export const trendingSampleFields = ['prepped_to_sequenced', 'received_to_prepped']

export interface Group {
  month: number[]
  group: string
}

export const getSteps = async (queryBody: any): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/get_steps`
  return axiosCall(axios.post.bind(null, endPoint, queryBody))
}

export const getWorkflows = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/step/workflows`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getUDFs = async ([workflow, step]): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/step/step_type/udfs?step_type=${step}&workflow=${workflow}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getRules = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/step/query_rules`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getReceivedSamples = async (dateMin, dateMax): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/received?&date_min=${
    dateMin + '-01'
  }&date_max=${dateMax + '-01'}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getTurnaroundTimes = async (
  dateMin,
  dateMax,
  group: 'priority' | 'tag'
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_ARNOLD_URL}/turnaround?&date_min=${
    dateMin + '-01'
  }&date_max=${dateMax + '-01'}&group=${group}`
  return axiosCall(axios.get.bind(null, endPoint))
}
