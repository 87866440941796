import * as React from 'react'
import { Layout, Menu } from 'antd'
import mainStyles from '../../App.module.css'
import { Link } from 'react-router-dom'
import { LabPrepsRoutes } from '../Routes'

export const LabPrepsHomePage = ({ isLoggedIn }) => {
  const { Sider, Content } = Layout

  const menuItems = [
    {
      key: 'steps',
      label: <Link to="/lab-preps/steps">Steps</Link>,
    },
    {
      key: 'received-samples',
      label: <Link to="/lab-preps/received-samples">Received samples</Link>,
    },
    {
      key: 'turnaround-times',
      label: <Link to="/lab-preps/turnaround-times">Turnaround times</Link>,
    },
  ]

  return (
    <Layout className={mainStyles.layout}>
      <Sider style={{ backgroundColor: 'transparent' }}>
        <Menu
          mode="inline"
          style={{ height: '100%' }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          selectedKeys={location.pathname.split('/').slice(-1)}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Content className={mainStyles.content}>
          <LabPrepsRoutes isLoggedIn={isLoggedIn} />
        </Content>
      </Layout>
    </Layout>
  )
}
