import React from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import {
  BlockOutlined,
  DatabaseOutlined,
  ExperimentOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { GenotypeRoutes } from '../Routes'
import mainStyles from '../../App.module.css'

export const GenotypeHomePage = ({ isLoggedIn }) => {
  const { Sider, Content } = Layout

  const menuItems = [
    {
      key: 'upload',
      icon: <UploadOutlined />,
      label: <Link to="/genotype/upload">Upload plate</Link>,
    },
    {
      key: 'plates',
      icon: <DatabaseOutlined />,
      label: <Link to="/genotype/plates">Plates</Link>,
    },
    {
      key: 'samples',
      icon: <ExperimentOutlined />,
      label: <Link to="/genotype/samples">Samples</Link>,
    },
    {
      key: 'match',
      icon: <BlockOutlined />,
      label: <Link to="/genotype/match">Match</Link>,
    },
    {
      key: 'users',
      icon: <UserOutlined />,
      label: <Link to="/genotype/users">Users</Link>,
    },
  ]

  return (
    <Layout>
      <Sider style={{ backgroundColor: 'transparent' }}>
        <Menu
          mode="inline"
          style={{ height: '100%' }}
          defaultSelectedKeys={['1']}
          selectedKeys={location.pathname.split('/').slice(-1)}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Content className={mainStyles.content}>
          <GenotypeRoutes isLoggedIn={isLoggedIn} />
        </Content>
      </Layout>
    </Layout>
  )
}
