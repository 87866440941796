import * as React from 'react'
import { useEffect, useState } from 'react'
import { getFailedStats } from '../services'
import { FailedJobs } from '../FailedJobs/FailedJobs'
import { FailedJob, GetFailedJobsResponse } from '../interfaces'

export const AnalysesStatsPage = () => {
  const [failedJobs, setFailedJobs] = useState<FailedJob[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getFailedStats().then((response: GetFailedJobsResponse) => {
      setFailedJobs(response?.jobs)
      setIsLoading(false)
    })
  }, [])

  return <FailedJobs failedJobs={failedJobs} isLoading={isLoading} />
}
