import React from 'react'
import styles from './FooterComponent.module.css'
import packageJson from '../../../../package.json'
import moment from 'moment'
import { cleanTitle } from '../services/helpers/helpers'
import { Layout } from 'antd'
const { Footer } = Layout

export const FooterComponent = ({ themeMode }) => {
  const lowerCaseText = packageJson.name
  const appName = cleanTitle(lowerCaseText)
  const currentYear = moment().year()
  return (
    <Footer
      data-testid="footer"
      className={styles.footer}
      style={{ backgroundColor: themeMode ? '#141414' : '#ffffff' }}
    >
      {`${appName}© ${currentYear} - The `}
      <a href={'https://www.scilifelab.se/units/clinical-genomics-stockholm/'}>Clinical Genomics</a>
      {` Research Intelligence Dashboard`}
    </Footer>
  )
}
