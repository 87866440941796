import React, { useState } from 'react'
import { Card, Input, Tooltip } from 'antd'
import { SampleMatchTable } from '../SampleMatchTable/SampleMatchTable'

export const MatchPage = () => {
  const [sampleId, setSampleId] = useState()

  const assignSampleId = (value) => {
    setSampleId(value.target.value)
  }

  return (
    <Card>
      <Tooltip title="Input a sample id to find matches">
        <Input style={{ maxWidth: 200 }} placeholder="Sample id" onChange={assignSampleId} />
      </Tooltip>
      <br />
      <br />
      <SampleMatchTable sampleId={sampleId} />
    </Card>
  )
}
