import React from 'react'
import { Result, Typography } from 'antd'
import { GithubOutlined } from '@ant-design/icons'

const { Text } = Typography
export const GitHubUnauthorized = () => (
  <div>
    <Result
      icon={<GithubOutlined />}
      title="You are not authenticated with GitHub"
      extra={
        <Text>
          To visualize the project info you need to login with your GitHub account by clicking on
          the Login button in the menu
        </Text>
      }
    />
  </div>
)
