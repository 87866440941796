import React, { useEffect, useState } from 'react'
import { Col, notification, Popconfirm, Row, Table, Tag } from 'antd'
import styles from '../SamplePage/SamplePage.module.css'
import { Link } from 'react-router-dom'
import { genotypeSampleStatus, notAvailableTag } from '../../Common/styleHelpers'
import { DeleteOutlined } from '@ant-design/icons'
import { deleteSample } from '../services'
import { RootState } from '../../../domain/rootReducer'
import { connect } from 'react-redux'

const mapState = ({ settings }: RootState) => ({ settings } as const)

export const SamplesTableComponent = ({ samples, isLoading, hideColumns, settings }) => {
  const [localSamples, setLocalSamples] = useState()
  useEffect(() => {
    setLocalSamples(samples)
  }, [samples])

  const confirmDeleteSample = ({ id }) => {
    deleteSample(id).then(() => {
      notification['success']({
        message: 'Sample deleted',
      })
      setLocalSamples(samples.filter((item) => item.id !== id))
    })
  }
  const columns = [
    {
      title: 'Sample id',
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: (id) => <Link to={`/genotype/samples/${id}`}>{id}</Link>,
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'Plate id',
      dataIndex: 'analyses',
      key: 'analyses',
      width: 150,
      render: (analysis) => (
        <Link to={`/genotype/plates/${analysis[0]?.plate_id}`}>{analysis[0]?.plate_id}</Link>
      ),
      sorter: (a, b) => a[0]?.plate_id.localeCompare(b[0]?.plate_id),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: any) =>
        status ? <Tag color={genotypeSampleStatus[status]}>{status?.toUpperCase()}</Tag> : <div />,
      filters: [
        {
          text: 'Fail',
          value: 'fail',
        },
        {
          text: 'Cancel',
          value: 'cancel',
        },
        {
          text: 'Pass',
          value: 'pass',
        },
        {
          text: 'Unknown',
          value: 'unknown',
        },
      ],
      onFilter: (value, record) =>
        typeof record?.status !== 'string' && value === 'unknown'
          ? true
          : record?.status?.indexOf(value) === 0,
    },
    {
      title: 'Details',
      dataIndex: 'detail',
      key: 'detail',
      width: 250,
      render: (details, status) => {
        return (
          <Row>
            {details?.status &&
              Object.entries(details.status).map((detail: any) => {
                return (
                  <Col key={detail[0]}>
                    {status.status === 'fail' &&
                      (detail[1] !== null ? (
                        <Tag color={genotypeSampleStatus[detail[1]]}>
                          {detail[0].charAt(0).toUpperCase() + detail[0].slice(1)}
                        </Tag>
                      ) : (
                        <Tag color={notAvailableTag[detail[1]]}>N/A</Tag>
                      ))}
                  </Col>
                )
              })}
          </Row>
        )
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) => <div>{comment}</div>,
    },
    {
      title: 'Delete sample',
      key: 'delete_sample',
      render: (sample_id: any) => (
        <Popconfirm
          title="Are you sure you want to delete this sample?"
          onConfirm={() => confirmDeleteSample(sample_id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ fontSize: '20px' }} />
        </Popconfirm>
      ),
    },
  ].filter(({ key }) => !hideColumns?.includes(key))

  return (
    <Table
      dataSource={localSamples}
      columns={columns}
      loading={isLoading}
      pagination={false}
      rowKey={(analyses) => analyses.id}
      tableLayout={'fixed'}
      rowClassName={(analyses) =>
        `${settings?.themeMode ? styles.darkCustomRow : styles.lightCustomRow} ${
          settings?.themeMode
            ? styles[`dark${analyses?.status}`]
            : styles[`light${analyses?.status}`]
        }`
      }
    />
  )
}

export const SamplesTable = connect(mapState)(SamplesTableComponent)
