import React from 'react'
import { Card, Tabs } from 'antd'
import { DocumentsTable } from './AtlasTables/DocumentsTable'
import { OwnersTable } from './AtlasTables/OwnersTable'
import { StatisticsPage } from './StatisticsPage'

const { TabPane } = Tabs

export const AtlasPage = ({ darkMode }) => {
  return (
    <>
      <Card>
        <Tabs type="card">
          <TabPane tab="Documents" key="1">
            <DocumentsTable />
          </TabPane>
          <TabPane tab="Owners" key="2">
            <OwnersTable />
          </TabPane>
          <TabPane tab="Statistics" key="3">
            <StatisticsPage darkMode={darkMode} />
          </TabPane>
        </Tabs>
      </Card>
    </>
  )
}
