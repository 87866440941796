import React from 'react'
import { Col, Tag, Row } from 'antd'
import { getOrderTagColor } from '../../../Common/styleHelpers'

const OrderStatusTag = ({ name, count }) => {
  if (count == 0) return null
  const color = getOrderTagColor(name)
  return (
    <Col>
      <Tag color={color}>
        <Row> {name.toUpperCase()}</Row>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>{count}</Row>
      </Tag>
    </Col>
  )
}

export const AnalysisStatus = ({ status }) => {
  return (
    <>
      <OrderStatusTag name="total" count={status.total} />
      <OrderStatusTag name="delivered" count={status.delivered} />
      <OrderStatusTag name="completed" count={status.completed} />
      <OrderStatusTag name="running" count={status.running} />
      <OrderStatusTag name="cancelled" count={status.cancelled} />
      <OrderStatusTag name="failed" count={status.failed} />
      <OrderStatusTag name="not received" count={status.not_received} />
      <OrderStatusTag name="lab prep" count={status.in_lab_preparation} />
      <OrderStatusTag name="sequencing" count={status.in_sequencing} />
      <OrderStatusTag name="failed seq. qc" count={status.failed_sequencing_qc} />
    </>
  )
}
