import { axiosCall } from '../Common/services/helpers/helpers'
import axios from 'axios'
import { SampleParameters } from './interfaces'

const { REACT_APP_BACKEND_GENOTYPE_URL } = process.env

export const analysisTypes = [
  { name: 'Genotype', id: 'genotype' },
  { name: 'Sequence', id: 'sequence' },
]

export const getPlates = async (
  orderBy = 'id',
  sortOrder = 'descend',
  skip = 0,
  limit = 60
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/plates/?order_by=${orderBy}&sort_order=${sortOrder}&skip=${skip}&limit=${limit}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getPlate = async (id): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/plates/${id}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getPlateAnalyses = async (id, skip, limit): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/?skip=${skip}&limit=${limit}&plate_id=${id}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const signOffPlate = async (plateId, methodDocument, methodVersion): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/plates/${plateId}/sign-off?method_document=${methodDocument}&method_version=${methodVersion}`
  return axiosCall(axios.patch.bind(null, endPoint, undefined))
}

export const getUsers = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/users/`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getUser = async (id: number): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/users/${id}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const postUser = async (user: any): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/users/`
  return axiosCall(axios.post.bind(null, endPoint, user))
}

export const putUserEmail = async (id: number, email: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/users/${id}/email?email=${email}`
  return axiosCall(axios.put.bind(null, endPoint, undefined))
}

export const deleteUser = async (user: any): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/users/${user}`
  return axiosCall(axios.delete.bind(null, endPoint))
}

export const putSample = async (
  id: string,
  parameter: 'comment' | 'status',
  value
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/${id}/${parameter}?${parameter}=${value}`
  return axiosCall(axios.put.bind(null, endPoint, undefined))
}

export const getSample = async (id): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/${id}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getSamples = async (parameters: SampleParameters): Promise<any> => {
  let URLParameters = ''
  Object.entries(parameters).map(([key, value]) => {
    if (value !== null && value !== '' && value !== undefined) {
      URLParameters = URLParameters + `${key}=${value}&`
    }
  })
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/?${URLParameters}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const deleteSample = async (id: any): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/${id}`
  return axiosCall(axios.delete.bind(null, endPoint))
}

export const uploadPlate = async (plate): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/plates/plate`
  return axiosCall(axios.post.bind(null, endPoint, plate))
}

export const getMatches = async (
  sample,
  analysisType,
  comparisonSet,
  dateMin,
  dateMax
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_GENOTYPE_URL}/samples/${sample}/match?analysis_type=${analysisType}&comparison_set=${comparisonSet}&date_min=${dateMin}&date_max=${dateMax}`
  return axiosCall(axios.get.bind(null, endPoint))
}
