import styles from './Link.module.css'
import * as React from 'react'

export const Link = ({ title, url, img }) => (
  <div key={title} className={styles.appItem}>
    <a href={url}>
      <img className={styles.appLogo} src={img} alt={title} width="100" height="100"></img>
      <div className={styles.appName}>{title}</div>
    </a>
  </div>
)
