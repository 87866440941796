const supportLinks = {
  susy: 'https://clinical-scilifelab.supportsystem.com/scp/tickets.php?number=',
  freshDesk: 'https://scilifelab.freshdesk.com/a/tickets/',
}

/**
 * This function is temporary and will be removed once all tickets are migrated to FreshDesk.
 * It currently routes to SuSy for non-7-digit ticket IDs and FreshDesk for 7-digit ticket IDs.
 */

export const supportSystemTicketLink = (ticketId: string | number | null | undefined): string => {
  const ticketIdStr = String(ticketId ?? '')
  if (ticketIdStr?.length === 7) {
    return `${supportLinks.freshDesk}${ticketIdStr}`
  }

  return `${supportLinks.susy}${ticketIdStr}`
}
