import { createErrorNotification, OpenNotification } from '../Common/services/helpers/helpers'
import { store } from '../../domain/store'
import { default as axios } from 'axios'

const gitHubURL = 'https://api.github.com/'
const owner = 'Clinical-Genomics'
const repo = 'project-planning'
const repoURL = `${gitHubURL}repos/${owner}/${repo}`
const orgURL = `${gitHubURL}orgs/${owner}`

const getToken = () => {
  const token = store?.getState()?.settings?.gitHubToken
  return token ? token : ''
}

export const getIssues = async (token = getToken()): Promise<any> => {
  let haveMoreIssues = true
  let response: any[] = []
  let page = 1
  while (haveMoreIssues) {
    try {
      const request = await fetch(`${repoURL}/issues?&page=${page}&per_page=100&state=all`, {
        mode: 'cors',
        headers: { Authorization: `token ${token}` },
      })
      await request.json().then((item) => {
        response = [...response, ...item]
        if (item.length < 100) haveMoreIssues = false
      })

      page++
    } catch (error) {
      haveMoreIssues = false
      OpenNotification({
        type: 'error',
        message: 'Unable to fetch',
        description: 'Could not fetch GitHub issues',
      })
    }
  }
  return response
}

export const getProjects = async (token = getToken()): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${repoURL}/projects`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const getUsers = async (token = getToken()): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgURL}/members?&per_page=100`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const getMilestone = async (token = getToken()): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${repoURL}/milestones`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const getLabels = async (token = getToken()): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${repoURL}/labels`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const getItems = async (token = getToken(), url): Promise<any> => {
  let response = []
  try {
    const request = await fetch(url, {
      mode: 'cors',
      headers: { Authorization: `token ${token}` },
    })
    response = await request.json()
  } catch (error) {
    OpenNotification({
      type: 'error',
      message: 'Unable to fetch',
      description: `Could not fetch data from ${url}`,
    })
  }
  return response
}
